<script type="text/javascript">
    function osType() {
        let userAgent = navigator.userAgent;
        if (userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) { // iphone or ipad
            // alert("ios:" + userAgent)
            return "ios";
            // return;
        } else if (userAgent.indexOf("Windows NT 5.1") > -1 || userAgent.indexOf("Windows XP") > -1) { // xp
            return "xp";
        } else if (userAgent.indexOf("Macintosh") > 0) {
            return "mac";
        } else if (userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1) { // 安卓
            return "android";
        } else if (userAgent.match("/MicroMessenger/i")) { // 微信
            return "wechat";
        } else if (userAgent.indexOf("Windows")) {
            return "windows";
        } else {
            // alert(userAgent);
            alert("现提供Windows版本、Mac版本、IOS版本和Android版本，其他版本近期上线，敬请期待!");
            return ""
        }
    }

    function download() {
        window.open("https://apps.apple.com/cn/app/%E4%B8%93%E6%B3%A8%E7%9A%84%E7%8C%AB/id1483734645?l=zh&ls=index");
        // if (osType() === "mac") {
        //     // window.open("https://apps.apple.com/cn/app/%E7%A6%85%E8%AE%B0%E5%88%9B%E4%BD%9C-zenwriter/id1457373570")
        //     alert("暂未上架，敬请期待")
        //     // window.open("");
        // } else if (osType() === "windows") {
        //     alert("暂未上架，敬请期待")
        //     // window.open("");
        //     // window.open("http://file.jinlixian.com/file/ZenWriter_installer.exe")
        // } else if (osType() === "android") {
        //     alert("暂未上架，敬请期待")
        //     window.open("");
        //     // window.open("http://file.jinlixian.com/file/Alpha/ZenWriter_installer.apk")
        // } else if (osType() === "ios") {
        //     alert("暂未上架，敬请期待")
        //     // window.open("");
        //     // window.open("https://apps.apple.com/cn/app/%E7%A6%85%E8%AE%B0%E5%88%9B%E4%BD%9C-zenwriter-%E5%85%A8%E6%96%B0%E7%9A%84%E5%88%9B%E4%BD%9C%E4%BD%93%E9%AA%8C/id1476814306");
        // } else {
        //     // alert("现提供Windows版本和Mac版本，其他版本近期上线，敬请期待!");
        //     alert("暂未上架，敬请期待")
        // }
    }
    export default {
        osType,
        download
    }
</script>

