<template>
    <div>
<!--        <privacy_clauseText></privacy_clauseText>-->
<!--        <privacy_clause></privacy_clause>-->
<!--        <privacy_clause-com></privacy_clause-com>-->
        <privacy_clause_com>
        </privacy_clause_com>
    </div>

</template>

<script>
    import privacy_clause_com from "./components/privacy_clause_com";
    export default {
        name: "privacy_clause",
        components: {privacy_clause_com},
    }
</script>

<style scoped>

</style>