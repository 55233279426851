<template>
    <div class="chr-header" id="js-header" aria-expanded="false" data-comp="Header" role="banner">
        <div class="chr-header__wrapper">
            <div class="chr-header__hamburger">
                <div class="chr-header__hamburger-wrapper" id="js-hamburger-button">
<!--                    <button type="button" class="chr-header__hamburger-button" aria-controls="js-header__drawer"-->
<!--                            aria-expanded="false" aria-label="打开抽屉式导航栏">-->
<!--                        <svg role="img" class="chr-header__hamburger-icon chr-header__hamburger-icon&#45;&#45;standard"-->
<!--                             aria-labelledby="hamburger-title">-->
<!--                            <title id="hamburger-title">菜单</title>-->
<!--                            <use xlink:href="#hamburger"></use>-->
<!--                        </svg>-->
<!--                        <svg role="img" class="chr-header__hamburger-icon chr-header__hamburger-icon&#45;&#45;reversed"-->
<!--                             aria-labelledby="hamburger-reversed-title">-->
<!--                            <title id="hamburger-reversed-title">菜单</title>-->
<!--                            <use xlink:href="#hamburger"></use>-->
<!--                        </svg>-->
<!--                    </button>-->
                </div>
            </div>
            <div class="chr-header__lockup">
                <div class="chr-header__logo">
                    <a href="/"> 专注的猫 </a>
                </div>
<!--                <a href="https://www.google.cn/intl/zh-CN/chrome/#jump-content" class="chr-header__jump-to-content">-->
<!--                    <span class="chr-header__jump-to-content-text">Skip to content</span>-->
<!--                </a>-->
            </div>
        </div>
        <button v-on:click="download" type="button"
                class="chr-cta__button chr-cta__button--chr-cta__button chr-cta__button--blue js-download show"
                id="js-download-header" ga-on="click" ga-event-category="cta" ga-event-action="clicked"
                ga-event-label="download|nav" data-g-event="cta" data-g-action="clicked" data-g-label="download|nav">下载
            专注的猫
        </button>
<!--        <div id="js-header__drawer" class="chr-header__drawer" aria-label="Navigation drawer">-->
<!--            <div class="chr-header__drawer-content">-->
<!--                <div class="chr-header__logo">-->
<!--                    <a href="https://www.google.cn/intl/zh-CN/chrome/" class=" chr-header__logo-link" ga-on="click"-->
<!--                       ga-event-category="main_navigation" ga-event-action="clicked" ga-event-label="google-chrome"-->
<!--                       data-g-event="main_navigation" data-g-action="clicked" data-g-label="google-chrome">-->
<!--                        <svg role="img" class="chr-header__logo-icon" aria-labelledby="color-google-logo-drawer-title">-->
<!--                            <title id="color-google-logo-drawer-title"></title>-->
<!--                            <use xlink:href="#color-google-logo"></use>-->
<!--                            <image src="/chrome/static/images/fallback/google-chrome-logo.jpg" xlink:href=""-->
<!--                                   alt="Google Chrome" width="160" height="24" class="svg-fallback"></image>-->
<!--                        </svg>-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div id="js-drawer-backdrop" class="chr-header__drawer-backdrop"></div>
    </div>
</template>

<script>
    import common from "../common";
    export default {
        name: "Head",
        metaInfo: {
            title: "专注的猫 - 沉迷于手机会爆炸"
        },
        methods: {
            download:
                function () {
                    common.download();
                }
        }
    }
</script>

<style>
</style>