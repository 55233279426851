<template>
  <div>
    <section>
      <div class="chr-full-bleed-hero home" id="chr-hero">
        <div class="chr-wrapper">
          <div class="chr-full-bleed-hero__grid chr-grid">
            <div
              class="chr-grid__col chr-grid__col--6 chr-grid__col--offset-3 chr-grid__col--align-middle"
            >
              <img
                src="../static/img/logo.png"
                alt="专注的猫 沉迷于手机会爆炸"
                class="chr-full-bleed-hero__image"
                data-img-fallback="../img/logo.png"
              />
              <h1 class="chr-headline-1 chr-full-bleed-hero__title">专注的猫</h1>
              <p class="chr-subheadline chr-full-bleed-hero__sub">沉迷于手机会爆炸</p>
              <img
                v-on:click="download"
                type="image"
                src="../static/img/app-store-download.png"
                id="js-download-hero"
                ga-on="click"
                ga-event-category="cta"
                ga-event-action="clicked"
                ga-event-label="download|hero|chrome-homepage"
                data-g-event="cta"
                data-g-action="clicked"
                data-g-label="download|hero|chrome-homepage"
              >
            </div>
          </div>
          <a
            class="chr-anchor-arrow chr-full-bleed-hero__anchor"
            href="#feature-productivity"
            data-comp="AnchorArrow"
            ga-on="click"
            ga-event-category="scroll"
            ga-event-action="clicked"
            ga-event-label="down-arrow|hero|chrome-homepage"
            data-g-event="scroll"
            data-g-action="clicked"
            data-g-label="down-arrow|hero|chrome-homepage"
          >下一页</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import common from "../common";
export default {
  name: "download",
  methods: {
    download: function() {
      common.download();
    }
  }
};
</script>

<style>
</style>