<template>
    <div>
        <section>
            <div data-comp="TrackedSection" data-trackedsection="6|download-chrome-today|chrome-homepage"
                 data-trackedoffset="" class="chr-download-now">
                <div class="chr-wrapper">
                    <div class="chr-grid">
                        <div class="chr-grid__col chr-grid__col--8 chr-grid__col--offset-2 chr-grid__col--align-middle">
                            <h2 class="chr-headline-2 chr-download-now__title">立即下载 专注的猫</h2>
                            <!-- <button v-on:click="download" type="button"
                                    class="chr-cta__button chr-cta__button--reversed js-download show"
                                    id="js-download-now" ga-on="click" ga-event-category="cta" ga-event-action="clicked"
                                    ga-event-label="download|bottom|chrome-homepage" data-g-event="cta"
                                    data-g-action="clicked" data-g-label="download|bottom|chrome-homepage">下载 专注的猫
                            </button> -->
              <img
                v-on:click="download"
                type="image"
                src="../static/img/app-store-download.png"
                id="js-download-hero"
                ga-on="click"
                ga-event-category="cta"
                ga-event-action="clicked"
                ga-event-label="download|hero|chrome-homepage"
                data-g-event="cta"
                data-g-action="clicked"
                data-g-label="download|hero|chrome-homepage"
              >
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="chr-modal" id="js-eula-modal">
            <div class="chr-modal-dialog__overlay js-eula-overlay"></div>
            <div class="chr-modal-dialog eula js-eula-dialog" aria-hidden="true" role="dialog" tabindex="0"
                 aria-labelledby="js-eula-content" aria-describedby="js-eula-content"
                 style="left: 672px; top: 191.2px;">
                <a href="#"
                   class=" chr-modal-dialog__close no-text js-eula-close"
                   ga-on="click" ga-event-category="eula" ga-event-action="clicked" ga-event-label="eula-close"
                   data-g-event="eula" data-g-action="clicked" data-g-label="eula-close">关闭</a>
                <div id="js-eula-content" class="eula-content stablechannel">
                    <div class="chr-platform-list chr-modal-dialog__header">
                        <div class="os os-win">
                            <h2 id="eula-u-h-os-win">下载 专注的猫（Windows 版）</h2>
                            <p id="eula-u-d-channel-win-stable" class="platform channel-win-stable">适用于 Windows
                                10/8.1/8/7 32位/64位</p>
                            <!--                            <p id="eula-u-d-channel-win-stable" class="platform channel-win-stable">适用于 Windows-->
                            <!--                                10/8.1/8/7 32-->
                            <!--                                位。</p>-->
                            <!--                            <p id="eula-u-d-channel-win64-stable" class="platform channel-win64-stable">适用于 Windows-->
                            <!--                                10/8.1/8/7-->
                            <!--                                64 位。</p>-->
                            <!--                    <p id="eula-u-d-channel-win49-stable" class="platform channel-win49-stable">此计算机将不会再收到 Google Chrome-->
                            <!--                        更新，因为 Windows XP 和 Windows Vista 不再受支持。-->
                            <!--                    </p>-->
                        </div>
                        <div class="os os-mac">
                            <h2 id="eula-u-h-os-mac">下载 专注的猫（Mac 版）</h2>
                            <p id="eula-u-d-channel-mac-stable" class="platform channel-mac-stable">适用于 Mac OS X 10.10
                                或更高版本。</p>
                            <p id="eula-u-d-channel-mac49-stable" class="platform channel-mac49-stable">此计算机将不会再收到
                                专注的猫
                                更新，因为 Mac OS X 10.6 - 10.9 不再受支持。
                            </p>
                            <p id="eula-u-d-channel-mac65-stable" class="platform channel-mac65-stable">此计算机将不会再收到
                                专注的猫
                                更新，因为 Mac OS X 10.6 - 10.9 不再受支持。
                            </p>
                        </div>
                        <!--                <div class="os os-linux">-->
                        <!--                    <h2 id="eula-u-jsLinuxDistroOptions-os-linux">下载 专注的猫（Linux 版）</h2>-->
                        <!--                    <p id="eula-u-d-channel-linux-stable" class="platform channel-linux-stable">-->
                        <!--                        Debian/Ubuntu/Fedora/openSUSE。</p>-->
                        <!--                    <h3 id="linux-distro-options-label">请选择下载包：</h3>-->
                        <!--                    <fieldset aria-labelledby="linux-distro-options-label">-->
                        <!--                        <div class="chr-radio">-->
                        <!--                            <input id="js-linux-ubuntu" name="linux-distro-options" checked="checked"-->
                        <!--                                   aria-checked="true" tabindex="0" type="radio"-->
                        <!--                                   class="chr-radio__input js-linux-distro-options" value="linux_ubuntu_x86_64">-->
                        <!--                            <label for="js-linux-ubuntu" class="chr-radio__label">64 位 .deb（适用于 Debian/Ubuntu）</label>-->
                        <!--                        </div>-->
                        <!--                        <div class="chr-radio">-->
                        <!--                            <input id="js-linux-fedora" name="linux-distro-options" aria-checked="false" tabindex="-1"-->
                        <!--                                   type="radio" class="chr-radio__input js-linux-distro-options"-->
                        <!--                                   value="linux_fedora_x86_64">-->
                        <!--                            <label for="js-linux-fedora" class="chr-radio__label">64 位 .rpm（适用于 Fedora/openSUSE）</label>-->
                        <!--                        </div>-->
                        <!--                    </fieldset>-->
                        <!--                    <p>您使用的不是 Debian/Ubuntu 或 Fedora/openSUSE？请在<a id="js-linux-community" target="_blank"-->
                        <!--                                                                   href="https://chromium.googlesource.com/chromium/src/+/master/docs/linux_chromium_packages.md">此处</a>查看是否有适合您所用系统的相关支持版本。-->
                        <!--                    </p>-->
                        <!--                </div>-->
                        <div class="os os-ios">
                            <h2 id="eula-u-h-os-ios">下载 专注的猫（iOS 版）</h2>
                        </div>
                    </div>
                    <div class="chr-modal-dialog__content">
                        <div class="eula-legal-info">
                            <div class="eula-legal-info__wrapper">
                                <!--                                隐私政策-->
                                <privacy_clause_com/>
                            </div>
                            <div class="eula-legal-info__link">
                                <a href="privacy_clause.html"
                                   id="js-print-friendly-eula" target="_blank" rel="noopener" ga-on="click"
                                   ga-event-category="chrome-body-link" ga-event-action="clicked"
                                   ga-event-label="eula:printer-friendly-version" data-g-event="chrome-body-link"
                                   data-g-action="clicked" data-g-label="eula:printer-friendly-version">便于打印的版本</a>
                            </div>
                        </div>
                        <label id="js-default-cb"></label>
                        <label id="js-stats-cb"></label>
                        <!--                        <div class="os os-linux disclaimer">-->
                        <!--                            <p><strong>注意</strong>：安装 Google Chrome 时，<strong>也会一并添加 Google 存储区</strong>，这样，您的系统就会及时自动更新-->
                        <!--                                Google-->
                        <!--                                Chrome。如果您不希望添加 Google 存储区，请先执行“sudo touch /etc/default/google-chrome”，然后再安装程序包。</p>-->
                        <!--                        </div>-->
                        <!--                        <div class="os os-win" style="display: none">-->
                        <!--                            <div class="chr-checkbox default-browser-opt">-->
                        <!--                                <input id="js-default-cb" checked="checked" type="checkbox" class="chr-checkbox__input"-->
                        <!--                                       value="set">-->
                        <!--                                <label for="js-default-cb" class="chr-checkbox__labelchr-copy">将 专注的猫-->
                        <!--                                    设为我的默认浏览器</label>-->
                        <!--                            </div>-->
                        <!--                            <div class="chr-checkbox ">-->
                        <!--                                <input id="js-stats-cb" checked="checked" type="checkbox" class="chr-checkbox__input"-->
                        <!--                                       value="">-->
                        <!--                                <label for="js-stats-cb" class="chr-checkbox__labelchr-copy">-->
                        <!--                                    将使用情况统计信息和崩溃报告自动发送给-->
                        <!--                                    Google，帮助我们完善-->
                        <!--                                    Google Chrome。-->
                        <!--                                    <a href="https://www.google.com/support/chrome/bin/answer.py?answer=96817&amp;hl=en"-->
                        <!--                                       target="_blank" rel="noopener" ga-on="click" ga-event-category="chrome-body-link"-->
                        <!--                                       ga-event-action="clicked" ga-event-label="eula:learn-more"-->
                        <!--                                       data-g-event="chrome-body-link" data-g-action="clicked"-->
                        <!--                                       data-g-label="eula:learn-more">了解详情</a></label>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                    <div class="chr-modal-dialog__buttons">
                        <button type="button" class="chr-cta__button chr-cta__button--blue" id="js-accept-install"
                                ga-on="click"
                                ga-event-category="eula" ga-event-action="clicked" ga-event-label="eula-acceptinstall"
                                data-g-event="eula" data-g-action="clicked" data-g-label="eula-acceptinstall"
                                name="accept">
                            接受并安装
                        </button>
                        <span id="js-throbber" class="throbber">
      <img src="../static/img/throbber_fast.gif" alt="正在加载"
           srcset="../static/img/throbber_fast.gif, ../static/img/throbber_fast-2x.gif 2x"
           title="正在加载"></span>
                    </div>
                </div>
                <div id="js-other-platform-modal" class="other-platform stablechannel">
                    <div class="chr-modal-dialog__header">
                        <h2 id="dialog-o-h-os-header">下载 专注的猫</h2>
                        <div class="os os-win">
                            <button v-on:click="download" type="button"
                                    class="chr-cta__button chr-cta__button--blue chr-modal-dialog__button js-download-current"
                                    ga-on="click" ga-event-category="cta" ga-event-action="clicked"
                                    ga-event-label="download-tiffany" data-g-event="cta" data-g-action="clicked"
                                    data-g-label="download-tiffany">下载 Windows 版
                            </button>
                            <p id="dialog-o-d-channel-win-stable" class="platform channel-win-stable">适用于 Windows
                                10/8.1/8/7 32位/64位</p>
                            <!--                            <p id="dialog-o-d-channel-win64-stable" class="platform channel-win64-stable">适用于 Windows-->
                            <!--                                10/8.1/8/7-->
                            <!--                                64 位</p>-->
                            <p id="dialog-o-d-channel-win49-stable" class="platform channel-win49-stable">
                                <!--                                此计算机将不会再收到 Google-->
                                <!--                                Chrome 更新，因为 Windows XP 和 Windows Vista 不再受支持。-->
                                Windows XP 和 Windows Vista 不再受支持。
                            </p>
                        </div>
                        <div class="os os-mac">
                            <button type="button"
                                    class="chr-cta__button chr-cta__button--blue chr-modal-dialog__button js-download-current"
                                    ga-on="click" ga-event-category="cta" ga-event-action="clicked"
                                    ga-event-label="download-tiffany" data-g-event="cta" data-g-action="clicked"
                                    data-g-label="download-tiffany">下载 Mac 版
                            </button>
                            <p id="dialog-o-d-channel-mac-stable" class="platform channel-mac-stable">Mac OS X 10.10
                                或更高版本</p>
                            <p id="dialog-o-d-channel-mac49-stable" class="platform channel-mac49-stable">此计算机将不会再收到
                                专注的猫 更新，因为 Mac OS X 10.6 - 10.9 不再受支持。
                            </p>
                            <p id="dialog-o-d-channel-mac65-stable" class="platform channel-mac65-stable">此计算机将不会再收到
                                专注的猫 更新，因为 Mac OS X 10.6 - 10.9 不再受支持。
                            </p>
                        </div>
                        <!--                        <div class="os os-linux">-->
                        <!--                            <button type="button"-->
                        <!--                                    class="chr-cta__button chr-cta__button&#45;&#45;blue chr-modal-dialog__button js-download-current"-->
                        <!--                                    ga-on="click" ga-event-category="cta" ga-event-action="clicked"-->
                        <!--                                    ga-event-label="download-tiffany" data-g-event="cta" data-g-action="clicked"-->
                        <!--                                    data-g-label="download-tiffany">下载 Linux 版-->
                        <!--                            </button>-->
                        <!--                            <p id="dialog-o-d-channel-linux-stable" class="platform channel-linux-stable">-->
                        <!--                                Debian/Ubuntu/Fedora/openSUSE</p>-->
                        <!--                        </div>-->
                    </div>
                    <div class="chr-modal-dialog__content">
                        <!--                        <h4>下载适用于手机或平板电脑的版本</h4>-->
                        <!--                        <ul>-->
                        <!--                            <li class="other-android">-->
                        <!--                                <a href="https://play.google.com/store/apps/details?id=com.android.chrome&amp;pcampaignid=websitedialog"-->
                        <!--                                   id="js-other-android" target="_blank" rel="noopener" ga-on="click"-->
                        <!--                                   ga-event-category="chrome-cta-button" ga-event-action="clicked"-->
                        <!--                                   ga-event-label="other-platforms:android" data-g-event="chrome-cta-button"-->
                        <!--                                   data-g-action="clicked" data-g-label="other-platforms:android">Android</a></li>-->
                        <!--                            <li class="other-ios">-->
                        <!--                                <a href="https://itunes.apple.com/us/app/chrome/id535886823" id="js-other-ios"-->
                        <!--                                   target="_blank"-->
                        <!--                                   rel="noopener" ga-on="click" ga-event-category="chrome-cta-button"-->
                        <!--                                   ga-event-action="clicked"-->
                        <!--                                   ga-event-label="other-platforms:ios" data-g-event="chrome-cta-button"-->
                        <!--                                   data-g-action="clicked"-->
                        <!--                                   data-g-label="other-platforms:ios">iOS</a></li>-->
                        <!--                        </ul>-->
                        <h4>下载适用于其他桌面操作系统的版本</h4>
                        <ul>
                            <li class="other-win64">
                                <a href="#" id="js-other-win64"
                                   class=" js-other-option"
                                   ga-on="click" ga-event-category="chrome-cta-button" ga-event-action="clicked"
                                   ga-event-label="other-platforms:windows-7-to-10-64-bit"
                                   data-g-event="chrome-cta-button"
                                   data-g-action="clicked" data-g-label="other-platforms:windows-7-to-10-64-bit">Windows
                                    10/8.1/8/7 32位/64位</a></li>
                            <!--                            <li class="other-win">-->
                            <!--                                <a href="https://www.google.cn/intl/zh-CN/chrome/#" id="js-other-win"-->
                            <!--                                   class=" js-other-option"-->
                            <!--                                   ga-on="click" ga-event-category="chrome-cta-button" ga-event-action="clicked"-->
                            <!--                                   ga-event-label="other-platforms:windows-7-to-10-32-bit"-->
                            <!--                                   data-g-event="chrome-cta-button"-->
                            <!--                                   data-g-action="clicked" data-g-label="other-platforms:windows-7-to-10-32-bit">Windows-->
                            <!--                                    10/8.1/8/7 32 位</a></li>-->
                            <li class="other-mac">
                                <a href="https://www.google.cn/intl/zh-CN/chrome/#" id="js-other-mac"
                                   class=" js-other-option"
                                   ga-on="click" ga-event-category="chrome-cta-button" ga-event-action="clicked"
                                   ga-event-label="other-platforms:mac-10-10-later" data-g-event="chrome-cta-button"
                                   data-g-action="clicked" data-g-label="other-platforms:mac-10-10-later">Mac OS X 10.10
                                    或更高版本</a></li>
                            <!--                            <li class="other-linux">-->
                            <!--                                <a href="https://www.google.cn/intl/zh-CN/chrome/#" id="js-other-linux"-->
                            <!--                                   class=" js-other-option"-->
                            <!--                                   ga-on="click" ga-event-category="chrome-cta-button" ga-event-action="clicked"-->
                            <!--                                   ga-event-label="other-platforms:linux" data-g-event="chrome-cta-button"-->
                            <!--                                   data-g-action="clicked" data-g-label="other-platforms:linux">Linux</a></li>-->
                        </ul>
                        <!--                        <h4 class="frozen-version">已冻结的版本</h4>-->
                        <!--                        <ul class="frozen-version">-->
                        <!--                            <li class="other-winXP">-->
                        <!--                                <a href="https://www.google.cn/intl/zh-CN/chrome/#" id="js-other-xp"-->
                        <!--                                   class=" js-other-option"-->
                        <!--                                   ga-on="click" ga-event-category="chrome-cta-button" ga-event-action="clicked"-->
                        <!--                                   ga-event-label="other-platforms:windows-xp" data-g-event="chrome-cta-button"-->
                        <!--                                   data-g-action="clicked" data-g-label="other-platforms:windows-xp">Windows XP</a></li>-->
                        <!--                            <li class="other-winVista">-->
                        <!--                                <a href="https://www.google.cn/intl/zh-CN/chrome/#" id="js-other-vista"-->
                        <!--                                   class=" js-other-option"-->
                        <!--                                   ga-on="click" ga-event-category="chrome-cta-button" ga-event-action="clicked"-->
                        <!--                                   ga-event-label="other-platforms:windows-vista" data-g-event="chrome-cta-button"-->
                        <!--                                   data-g-action="clicked" data-g-label="other-platforms:windows-vista">Windows-->
                        <!--                                    Vista</a></li>-->
                        <!--                            <li class="other-mac49">-->
                        <!--                                <a href="https://www.google.cn/intl/zh-CN/chrome/#" id="js-other-mac49"-->
                        <!--                                   class=" js-other-option"-->
                        <!--                                   ga-on="click" ga-event-category="chrome-cta-button" ga-event-action="clicked"-->
                        <!--                                   ga-event-label="other-platforms:mac-10-6-to-10-8" data-g-event="chrome-cta-button"-->
                        <!--                                   data-g-action="clicked" data-g-label="other-platforms:mac-10-6-to-10-8">Mac 10.6 - -->
                        <!--                                    10.8</a>-->
                        <!--                            </li>-->
                        <!--                            <li class="other-mac65">-->
                        <!--                                <a href="https://www.google.cn/intl/zh-CN/chrome/#" id="js-other-mac65"-->
                        <!--                                   class=" js-other-option"-->
                        <!--                                   ga-on="click" ga-event-category="chrome-cta-button" ga-event-action="clicked"-->
                        <!--                                   ga-event-label="other-platforms:mac-10-9" data-g-event="chrome-cta-button"-->
                        <!--                                   data-g-action="clicked" data-g-label="other-platforms:mac-10-9">Mac 10.9</a></li>-->
                        <!--                        </ul>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import privacy_clause_com from "./privacy_clause_com";
    import common from "../common"

    export default {
        name: "down",
        components: {privacy_clause_com},
        self: this,
        methods: {
            download:
                function () {
                    common.download();
                }
        },

    }

</script>

<style>

</style>