<template>
    <div>

        <Head></Head>
        <div role="main" class="chr-main" id="jump-content">
            <div class="chr-full-bleed-hero home" id="chr-hero">
                <h1>专注的猫 隐私政策与使用条款 </h1>
                <privacy_clause_com></privacy_clause_com>
                <user_protocol_com></user_protocol_com>
            </div>
        </div>
        <Down></Down>
        <Foot></Foot>
    </div>
</template>

<script>
    import Head from './components/Head'
    import Foot from './components/Foot'
    import privacy_clause_com from './components/privacy_clause_com'
    import user_protocol_com from './components/user_protocol_com'
    import Down from "./components/down";

  /*  // import './js/installer' */
    import './js/main.v2.min'

    export default {
        name: "Privacy",
        components: {
            Down,
            Head,
            privacy_clause_com,
            user_protocol_com,
            Foot,
        },
    }
</script>

<style scoped>

</style>