<template>
    <div id="app" class="no-mobile ">
        <Head></Head>
        <div role="main" class="chr-main" id="jump-content">
            <Download></Download>
            <!--            <down></down>-->
            <!--            <Explain></Explain>-->
            <!--            id用来定位，如果显示widget的话，第一个widget的id应为feature-productivity，这个名称来自download里面的参数-->
            <section class="js-sticky-trigger" id="feature-productivity">
                <Widget v-for="item in message" v-bind:key="item.key" :message="item"></Widget>
            </section>
        </div>
        <Down></Down>
        <Foot></Foot>
    </div>
</template>

<script>

    import Head from './components/Head'
    import Foot from './components/Foot'
    import Down from './components/down'
    import Download from './components/download'
    import Widget from './components/widget'
    // import Explain from './components/Explain'
    /* import './js/installer' */
    import './js/main.v2.min'
    //
    export default {
        name: 'Home',
        components: {
            Head,
            Foot,
            Download,
            // Explain,
            Down,
            Widget
        },
        data: function () {
            return {
                message:
                    [
                        //     {
                        //         key: 1,
                        //         onLeft: true,
                        //         title: "体验更加现代化的创作体验",
                        //         say: "这是一款效率工具，它更简单，比简单更简单，做到如此简单的原因是因为它更强大，更懂创作的状态。" +
                        //             "如果您是一位创作者，选择专注的猫就对了。他最适合你。因为它的故事，却不止表面的简单这么简单" ,
                        //         img: require('./static/img/file.png')
                        //     },
                        //     {
                        //         key: 2,
                        //         title: "和朋友在一起",
                        //         say: "似乎创作的常态是孤独，常常我们因为孤独而伟大。" +
                        //             "而有的时候，我们与三五好友在一起，却更有创造力。这里避免繁琐与喧闹，但比拼与相互的鼓励却往往是效率的源泉。",
                        //         onLeft: false,
                        //         img: require('./static/img/ranking.png')
                        //     }
                    ]
            }
        }
    }

</script>

<style>
    @import "./css/style.css";
    @import "./css/test.css";
    @import "./css/main.v2.min.css";

</style>
