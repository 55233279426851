<template>
    <footer class="chr-footer" data-comp="Footer">
        <div class="chr-footer-social">
            <div class="chr-footer-social__container">
                <h4 class="chr-footer-social__title">联系我们</h4>

                <ul class="chr-footer-social__list">
                    <li>
                        <a href="http://mail.qq.com/cgi-bin/qm_share?t=qm_mailme&email=mail@jinlixian.com" title="联系我们"
                           target="_blank" rel="noopener"
                           class=" chr-footer-social__link" ga-on="click"
                           ga-event-action="clicked"
                           data-g-event="chrome-footer-social"
                           data-g-action="clicked" data-g-label="follow-us:youtube">
                            <svg  class="chr-icon chr-icon--24" viewBox="0 0 1024 1024" version="1.1" width="200" height="200">
                                <path d="M892.915499 191.942567H130.4961c-36.734646 0-66.514903 29.780257-66.514903 66.514903v570.537208c0 36.734646 29.780257 66.514903 66.514903 66.514903h762.419399c36.734646 0 66.514903-29.780257 66.514903-66.514903V258.45747c0-36.735669-29.779234-66.514903-66.514903-66.514903z m-28.145013 63.960731c0.517793 0 1.032516 0.013303 1.544169 0.038885L511.87925 564.945863 157.434635 255.932973c0.401136-0.01535 0.802272-0.030699 1.206478-0.030699h706.129373z m0 575.645552H158.641113c-16.95516 0-30.699186-13.744026-30.699186-30.699186V314.877457l362.226859 315.797409c5.995551 5.995551 13.853519 8.991792 21.710464 8.991792 7.857968 0.001023 15.715937-2.996241 21.710464-8.991792l361.879958-315.49451v485.668285c0 16.95516-13.744026 30.700209-30.699186 30.700209z"
                                      p-id="1121"></path>
                            </svg>
                        </a>
                    </li>
                </ul>
                <!--        <a-->
                <!--          target="_blank"-->
                <!--          href="http://mail.qq.com/cgi-bin/qm_share?t=qm_mailme&email=mail@jinlixian.com"-->
                <!--          style="text-decoration:none;"-->
                <!--        >联系我们</a>-->
                <!--                <ul class="chr-footer-social__list">-->
                <!--                    <li>-->
                <!--                        <a href="https://www.youtube.com/user/googlechrome" title="YouTube" target="_blank" rel="noopener"-->
                <!--                           class=" chr-footer-social__link" ga-on="click" ga-event-category="chrome-footer-social"-->
                <!--                           ga-event-action="clicked" ga-event-label="follow-us:youtube" data-g-event="chrome-footer-social"-->
                <!--                           data-g-action="clicked" data-g-label="follow-us:youtube">-->
                <!--                            <svg role="img" class="chr-icon chr-icon&#45;&#45;24" aria-labelledby="social-youtube-title">-->
                <!--                                <title id="social-youtube-title">YouTube</title>-->
                <!--                                <use xlink:href="#social-youtube"></use>-->
                <!--                                <image src="/chrome/static/images/fallback/icon-youtube.jpg" xlink:href="" alt="Youtube"-->
                <!--                                       width="24" height="24" class="svg-fallback"></image>-->
                <!--                            </svg>-->
                <!--                        </a></li>-->
                <!--                    <li>-->
                <!--                        <a href="https://twitter.com/googlechrome" title="Twitter" target="_blank" rel="noopener"-->
                <!--                           class=" chr-footer-social__link" ga-on="click" ga-event-category="chrome-footer-social"-->
                <!--                           ga-event-action="clicked" ga-event-label="follow-us:twitter" data-g-event="chrome-footer-social"-->
                <!--                           data-g-action="clicked" data-g-label="follow-us:twitter">-->
                <!--                            <svg role="img" class="chr-icon chr-icon&#45;&#45;24" aria-labelledby="social-twitter-title">-->
                <!--                                <title id="social-twitter-title">Twitter</title>-->
                <!--                                <use xlink:href="#social-twitter"></use>-->
                <!--                                <image src="/chrome/static/images/fallback/icon-twitter.jpg" xlink:href="" alt="Twitter"-->
                <!--                                       width="24" height="24" class="svg-fallback"></image>-->
                <!--                            </svg>-->
                <!--                        </a></li>-->
                <!--                    <li>-->
                <!--                        <a href="https://www.facebook.com/googlechrome/" title="Facebook" target="_blank" rel="noopener"-->
                <!--                           class=" chr-footer-social__link" ga-on="click" ga-event-category="chrome-footer-social"-->
                <!--                           ga-event-action="clicked" ga-event-label="follow-us:facebook" data-g-event="chrome-footer-social"-->
                <!--                           data-g-action="clicked" data-g-label="follow-us:facebook">-->
                <!--                            <svg role="img" class="chr-icon chr-icon&#45;&#45;24" aria-labelledby="social-facebook-title">-->
                <!--                                <title id="social-facebook-title">Facebook</title>-->
                <!--                                <use xlink:href="#social-facebook"></use>-->
                <!--                                <image src="/chrome/static/images/fallback/icon-fb.jpg" xlink:href="" alt="Facebook"-->
                <!--                                       width="24" height="24" class="svg-fallback"></image>-->
                <!--                            </svg>-->
                <!--                        </a></li>-->
                <!--                </ul>-->
            </div>
        </div>
        <div class="chr-footer-links">
            <nav class="chr-footer-links__grid">
                <div class="chr-footer-links__group">
                    <h4 class="chr-footer-links__heading js-footer-link" tabindex="0">
                        专注的猫 系列
                        <svg role="img" class="chr-icon chr-icon--24">
                            <use xlink:href="#mi-expand"></use>
                        </svg>
                    </h4>
                    <ul class="chr-footer-links__list">
                        <li class="chr-footer-links__list-item footer-other-platform">
                            <a
                                    href="#"
                                    id="js-other-platform"
                                    class="chr-footer__link js-download chr-copy show"
                                    ga-on="click"
                                    ga-event-category="site_footer"
                                    ga-event-action="clicked"
                                    ga-event-label="other-platforms"
                                    data-g-event="site_footer"
                                    data-g-action="clicked"
                                    data-g-label="other-platforms"
                            >其他平台</a>
                        </li>
                        <!--                        <li class="chr-footer-links__list-item">-->
                        <!--                            <a href="https://www.google.com/chromebook/" target="_blank" rel="noopener"-->
                        <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                        <!--                               ga-event-action="clicked" ga-event-label="chromebooks" data-g-event="site_footer"-->
                        <!--                               data-g-action="clicked" data-g-label="chromebooks">Chromebook</a></li>-->
                        <!--                        <li class="chr-footer-links__list-item">-->
                        <!--                            <a href="https://www.google.com/chromecast/" target="_blank" rel="noopener"-->
                        <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                        <!--                               ga-event-action="clicked" ga-event-label="chromecast" data-g-event="site_footer"-->
                        <!--                               data-g-action="clicked" data-g-label="chromecast">Chromecast</a></li>-->
                        <!--                        <li class="chr-footer-links__list-item">-->
                        <!--                            <a href="https://www.google.com/chrome/cleanup-tool" target="_blank" rel="noopener"-->
                        <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                        <!--                               ga-event-action="clicked" ga-event-label="chrome-cleanup-tool" data-g-event="site_footer"-->
                        <!--                               data-g-action="clicked" data-g-label="chrome-cleanup-tool">Chrome 清理工具</a></li>-->
                    </ul>
                </div>
                <!--                <div class="chr-footer-links__group">-->
                <!--                    <h4 class="chr-footer-links__heading js-footer-link" tabindex="0">Google Enterprise-->
                <!--                        <svg role="img" class="chr-icon chr-icon&#45;&#45;24">-->
                <!--                            <use xlink:href="#mi-expand"></use>-->
                <!--                        </svg>-->
                <!--                    </h4>-->
                <!--                    <ul class="chr-footer-links__list">-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://cloud.google.com/chrome-enterprise/browser/download/" target="_blank"-->
                <!--                               rel="noopener" class=" chr-footer__link chr-copy" ga-on="click"-->
                <!--                               ga-event-category="site_footer" ga-event-action="clicked"-->
                <!--                               ga-event-label="enterprise-chrome-browser" data-g-event="site_footer" data-g-action="clicked"-->
                <!--                               data-g-label="enterprise-chrome-browser">Google Chrome 浏览器</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://enterprise.google.com/chrome/devices/" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="enterprise-devices" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="enterprise-devices">设备</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://cloud.google.com/" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="google-cloud" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="google-cloud">Google Cloud</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://gsuite.google.com/?hl=zh" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="g-suite" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="g-suite">G Suite</a></li>-->
                <!--                    </ul>-->
                <!--                </div>-->
                <!--                <div class="chr-footer-links__group">-->
                <!--                    <h4 class="chr-footer-links__heading js-footer-link" tabindex="0">教育-->
                <!--                        <svg role="img" class="chr-icon chr-icon&#45;&#45;24">-->
                <!--                            <use xlink:href="#mi-expand"></use>-->
                <!--                        </svg>-->
                <!--                    </h4>-->
                <!--                    <ul class="chr-footer-links__list">-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://edu.google.com/products/more-products/" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="education-chrome-browser"-->
                <!--                               data-g-event="site_footer" data-g-action="clicked" data-g-label="education-chrome-browser">Google-->
                <!--                                Chrome 浏览器</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://edu.google.com/products/devices/" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="education-devices" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="education-devices">设备</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://chrome.google.com/webstore/category/app/8-education?hl=en" target="_blank"-->
                <!--                               rel="noopener" class=" chr-footer__link chr-copy" ga-on="click"-->
                <!--                               ga-event-category="site_footer" ga-event-action="clicked" ga-event-label="web-store"-->
                <!--                               data-g-event="site_footer" data-g-action="clicked" data-g-label="web-store">网上应用店</a></li>-->
                <!--                    </ul>-->
                <!--                </div>-->
                <!--                <div class="chr-footer-links__group">-->
                <!--                    <h4 class="chr-footer-links__heading js-footer-link" tabindex="0">开发者和合作伙伴-->
                <!--                        <svg role="img" class="chr-icon chr-icon&#45;&#45;24">-->
                <!--                            <use xlink:href="#mi-expand"></use>-->
                <!--                        </svg>-->
                <!--                    </h4>-->
                <!--                    <ul class="chr-footer-links__list">-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://www.chromium.org/" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="chromium" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="chromium">Chromium</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://www.chromium.org/chromium-os" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="chrome-OS" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="chrome-OS">Chrome 操作系统</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://developer.chrome.com/webstore/?hl=zh" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="chrome-webstore" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="chrome-webstore">Chrome 网上应用店</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://www.chromeexperiments.com/" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="chrome-experiments" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="chrome-experiments">Chrome Experiments</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://www.google.cn/intl/zh-CN/chrome/beta/" class=" chr-footer__link chr-copy"-->
                <!--                               ga-on="click" ga-event-category="site_footer" ga-event-action="clicked"-->
                <!--                               ga-event-label="chrome-beta" data-g-event="site_footer" data-g-action="clicked"-->
                <!--                               data-g-label="chrome-beta">Chrome 测试版</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://www.google.cn/intl/zh-CN/chrome/dev/" class=" chr-footer__link chr-copy"-->
                <!--                               ga-on="click" ga-event-category="site_footer" ga-event-action="clicked"-->
                <!--                               ga-event-label="chrome-dev" data-g-event="site_footer" data-g-action="clicked"-->
                <!--                               data-g-label="chrome-dev">Chrome 开发者版</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://www.google.cn/intl/zh-CN/chrome/canary/" class=" chr-footer__link chr-copy"-->
                <!--                               ga-on="click" ga-event-category="site_footer" ga-event-action="clicked"-->
                <!--                               ga-event-label="chrome-canary" data-g-event="site_footer" data-g-action="clicked"-->
                <!--                               data-g-label="chrome-canary">Chrome Canary 版</a></li>-->
                <!--                    </ul>-->
                <!--                </div>-->
                <!--                <div class="chr-footer-links__group">-->
                <!--                    <h4 class="chr-footer-links__heading js-footer-link" tabindex="0">最新动态-->
                <!--                        <svg role="img" class="chr-icon chr-icon&#45;&#45;24">-->
                <!--                            <use xlink:href="#mi-expand"></use>-->
                <!--                        </svg>-->
                <!--                    </h4>-->
                <!--                    <ul class="chr-footer-links__list">-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://blog.google/products/chrome/" target="_blank" rel="noopener"-->
                <!--                               class=" chr-footer__link chr-copy" ga-on="click" ga-event-category="site_footer"-->
                <!--                               ga-event-action="clicked" ga-event-label="google-chrome-blog" data-g-event="site_footer"-->
                <!--                               data-g-action="clicked" data-g-label="google-chrome-blog">专注的猫 博客</a></li>-->
                <!--                        <li class="chr-footer-links__list-item">-->
                <!--                            <a href="https://support.google.com/chrome/?hl=zh&amp;rd=3#topic=7438008" target="_blank"-->
                <!--                               rel="noopener" class=" chr-footer__link chr-copy" ga-on="click"-->
                <!--                               ga-event-category="site_footer" ga-event-action="clicked" ga-event-label="chrome-help"-->
                <!--                               data-g-event="site_footer" data-g-action="clicked" data-g-label="chrome-help">专注的猫 帮助</a>-->
                <!--                        </li>-->
                <!--                    </ul>-->
                <!--                </div>-->
            </nav>
        </div>
        <div class="chr-footer__bottom">
            <!--            <div class="chr-footer__logo">-->
            <!--                <a href="https://www.google.com/" title="Google" target="_blank" rel="noopener">-->
            <!--                    <img src="../static/img/logo.png">-->
            <!--                    <svg role="img" class="chr-footer__logo-img" aria-labelledby="google-logo-title">-->
            <!--                        <title id="google-logo-title">Google</title>-->
            <!--                        <use xlink:href="#google-logo"></use>-->
            <!--                        <image src="/chrome/static/images/fallback/google-logo-one-color.jpg" xlink:href=""-->
            <!--                               alt="Google Logo" width="86" height="28" class="svg-fallback"></image>-->
            <!--                    </svg>-->
            <!--                </a></div>-->
            <nav class="chr-footer-glinks">
                <ul class="chr-footer-glinks__list">
                    <li class="chr-footer-glinks__list-item">
                        <a
                                href="./privacy.html"
                                class="chr-footer__link"
                                ga-on="click"
                                ga-event-category="nav-subfooter"
                                ga-event-action="clicked"
                                ga-event-label="privacy"
                                data-g-event="nav-subfooter"
                                data-g-action="clicked"
                                data-g-label="privacy"
                        >隐私权和使用条款</a>
                    </li>
                    <!--                    <li class="chr-footer-glinks__list-item">-->
                    <!--                        <a href="https://www.google.com/about/" target="_blank" rel="noopener" class=" chr-footer__link"-->
                    <!--                           ga-on="click" ga-event-category="nav-subfooter" ga-event-action="clicked"-->
                    <!--                           ga-event-label="about"-->
                    <!--                           data-g-event="nav-subfooter" data-g-action="clicked" data-g-label="about">Google 大全</a></li>-->
                    <!--                    <li class="chr-footer-glinks__list-item">-->
                    <!--                        <a href="https://www.google.com/about/products" target="_blank" rel="noopener"-->
                    <!--                           class=" chr-footer__link" ga-on="click" ga-event-category="nav-subfooter"-->
                    <!--                           ga-event-action="clicked" ga-event-label="products" data-g-event="nav-subfooter"-->
                    <!--                           data-g-action="clicked" data-g-label="products">Google 产品</a></li>-->
                    <li class="chr-footer-glinks__list-item">
<!--                        <div>冀ICP备18015206号</div>-->
                                                <a rel="noopener" class=" chr-footer__link"
                                                   ga-on="click" ga-event-category="nav-subfooter"
                                                   data-g-event="nav-subfooter" data-g-action="clicked" data-g-label="about">冀ICP备18015206号
                                                </a>
                    </li>
                </ul>
            </nav>
            <!--        <ul class="chr-footer-help-language">-->
            <!--            <li>-->
            <!--                <a href="https://support.google.com/chrome/?hl=en&amp;rd=3#topic=7438008" target="_blank" rel="noopener"-->
            <!--                   class=" chr-footer__link" ga-event-category="nav-subfooter" ga-event-action="" ga-event-label="help"-->
            <!--                   data-g-event="nav-subfooter" data-g-action="" data-g-label="help">-->
            <!--                    <svg role="img" class="chr-icon chr-icon&#45;&#45;24" aria-labelledby="mi-help-title">-->
            <!--                        <title id="mi-help-title">帮助</title>-->
            <!--                        <use xlink:href="#mi-help"></use>-->
            <!--                        <image src="/chrome/static/images/fallback/icon-help.jpg" xlink:href="" alt="Help" width="20"-->
            <!--                               height="21" class="svg-fallback"></image>-->
            <!--                    </svg>-->
            <!--                    帮助</a></li>-->
            <!--            <li class="chr-footer-language-dropdown">-->
            <!--                <label for="language-selector">Change language or region</label>-->
            <!--                <select class="chr-footer-language-dropdown__select js-footer-language-select" id="language-selector"-->
            <!--                        onchange="window.location.href=this[this.selectedIndex].value + window.location.search"-->
            <!--                        ga-on="click" ga-event-category="chrome-footer-dropdown" ga-event-action="clicked"-->
            <!--                        ga-event-label="footer:select-language" data-g-event="chrome-footer-dropdown"-->
            <!--                        data-g-action="clicked" data-g-label="footer:select-language">-->
            <!--                    <option value="/intl/ms/chrome/">Bahasa Melayu - Malaysia</option>-->
            <!--                    <option value="/intl/ca/chrome/">Català - Espanya</option>-->
            <!--                    <option value="/intl/da/chrome/">Dansk - Danmark</option>-->
            <!--                    <option value="/intl/de/chrome/">Deutsch - Deutschland</option>-->
            <!--                    <option value="/intl/et/chrome/">Eesti - Eesti</option>-->
            <!--                    <option value="/intl/en_au/chrome/">English - Australia</option>-->
            <!--                    <option value="/intl/en_ca/chrome/">English - Canada</option>-->
            <!--                    <option value="/intl/en_uk/chrome/">English - United Kingdom</option>-->
            <!--                    <option value="/intl/en_hk/chrome/">English - Hong Kong SAR China</option>-->
            <!--                    <option value="/intl/en_ie/chrome/">English - Ireland</option>-->
            <!--                    <option value="/intl/en_in/chrome/">English - India</option>-->
            <!--                    <option value="/intl/en_ph/chrome/">English - Philippines</option>-->
            <!--                    <option value="/intl/en_pk/chrome/">English - Pakistan</option>-->
            <!--                    <option value="/intl/en_sg/chrome/">English - Singapore</option>-->
            <!--                    <option value="/intl/en_us/chrome/">English - United States</option>-->
            <!--                    <option value="/intl/es-419/chrome/">Español - Latinoamérica</option>-->
            <!--                    <option value="/intl/es/chrome/">Español - España</option>-->
            <!--                    <option value="/intl/fil/chrome/">Filipino - Pilipinas</option>-->
            <!--                    <option value="/intl/fr/chrome/">Français - France</option>-->
            <!--                    <option value="/intl/hr/chrome/">Hrvatski - Hrvatska</option>-->
            <!--                    <option value="/intl/id/chrome/">Indonesia - Indonesia</option>-->
            <!--                    <option value="/intl/it/chrome/">Italiano - Italia</option>-->
            <!--                    <option value="/intl/lv/chrome/">Latviešu - Latvija</option>-->
            <!--                    <option value="/intl/lt/chrome/">Lietuvių - Lietuva</option>-->
            <!--                    <option value="/intl/hu/chrome/">Magyar - Magyarország</option>-->
            <!--                    <option value="/intl/nl/chrome/">Nederlands - Nederland</option>-->
            <!--                    <option value="/intl/no/chrome/">Norsk Bokmål - Norge</option>-->
            <!--                    <option value="/intl/pl/chrome/">Polski - Polska</option>-->
            <!--                    <option value="/intl/pt-PT/chrome/">Português - Portugal</option>-->
            <!--                    <option value="/intl/pt-BR/chrome/">Português - Brasil</option>-->
            <!--                    <option value="/intl/ro/chrome/">Română - România</option>-->
            <!--                    <option value="/intl/sk/chrome/">Slovenčina - Slovensko</option>-->
            <!--                    <option value="/intl/sl/chrome/">Slovenščina - Slovenija</option>-->
            <!--                    <option value="/intl/fi/chrome/">Suomi - Suomi</option>-->
            <!--                    <option value="/intl/sv/chrome/">Svenska - Sverige</option>-->
            <!--                    <option value="/intl/vi/chrome/">Tiếng Việt - Việt Nam</option>-->
            <!--                    <option value="/intl/tr/chrome/">Türkçe - Türkiye</option>-->
            <!--                    <option value="/intl/cs/chrome/">Čeština - Česko</option>-->
            <!--                    <option value="/intl/el/chrome/">Ελληνικά - Ελλάδα</option>-->
            <!--                    <option value="/intl/bg/chrome/">Български - България</option>-->
            <!--                    <option value="/intl/ru/chrome/">Русский - Россия</option>-->
            <!--                    <option value="/intl/sr/chrome/">Српски - Србија</option>-->
            <!--                    <option value="/intl/uk/chrome/">Українська - Україна</option>-->
            <!--                    <option value="/intl/iw/chrome/">עברית</option>-->
            <!--                    <option value="/intl/ar/chrome/">العربية - المملكة العربية السعودية</option>-->
            <!--                    <option value="/intl/fa/chrome/">فارسی</option>-->
            <!--                    <option value="/intl/hi/chrome/">हिन्दी - भारत</option>-->
            <!--                    <option value="/intl/th/chrome/">ไทย - ไทย</option>-->
            <!--                    <option value="/intl/zh-CN/chrome/" selected="">中文 - 中国</option>-->
            <!--                    <option value="/intl/zh-HK/chrome/">中文 - 中国香港特别行政区</option>-->
            <!--                    <option value="/intl/zh-TW/chrome/">中文 - 台灣</option>-->
            <!--                    <option value="/intl/ja/chrome/">日本語 - 日本</option>-->
            <!--                    <option value="/intl/ko/chrome/">한국어 - 대한민국</option>-->
            <!--                </select>-->
            <!--            </li>-->
            <!--        </ul>-->
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Foot"
    };
</script>

<style>
</style>