<template>
    <div class="two-col-split" data-comp="TrackedSection" data-trackedsection="2|a-faster-way|chrome-homepage"
         data-trackedoffset="" id="feature-productivity">
        <div class="chr-wrapper">
            <div class="chr-grid">
                <div class="chr-grid__col chr-grid__col--5 chr-grid__col-l--4 chr-grid__col--align-middle chr-grid__col--offset-1">
                    <header class="two-col-split__header chr-benefits-icon benefits-icon-fast">
                        <svg role="img">
                            <use xlink:href="#benefits-icon-fast"></use>
                        </svg>
                        <h2 class="chr-headline-2">{{message.title}}</h2>

                    </header>
                    <div>
                        <!--                        <p class="chr-copy">用户每次上网，一般都有一定的目的。无论这目的是大是小，是工作还是娱乐，Chrome-->
                        <!--                            所具备的智能工具和非凡速度都能让您在网络世界得心应手地操作、创作和探索。</p>-->
                        <p>{{message.say}}</p>
                    </div>
                    <!--                            <div class="two-col-split__footer">-->
                    <!--                                <a href="https://www.google.cn/intl/zh-CN/chrome/productivity/"-->
                    <!--                                   class="chr-link chr-link&#45;&#45;internal" ga-on="click" ga-event-category="body-icon"-->
                    <!--                                   ga-event-action="clicked"-->
                    <!--                                   ga-event-label="explore-all-features|a-faster-way|chrome-homepage"-->
                    <!--                                   data-g-event="body-icon" data-g-action="clicked"-->
                    <!--                                   data-g-label="explore-all-features|a-faster-way|chrome-homepage">探索-->
                    <!--                                    <svg role="img" class="chr-icon chr-icon&#45;&#45;link"-->
                    <!--                                         aria-labelledby="arrow-productivity-title">-->
                    <!--                                        <title id="arrow-productivity-title">箭头</title>-->
                    <!--                                        <use xlink:href="#mi-arrow-forward"></use>-->
                    <!--                                    </svg>-->
                    <!--                                </a>-->
                    <!--                            </div>-->
                </div>
                <!--                <div v-if=message.onLeft>-->

                <!--                <div v-if=message.onLeft class="chr-grid__col chr-grid__col&#45;&#45;5 chr-grid__col&#45;&#45;6 two-col-split__image-wrapper two-col-split__image-left chr-grid__col-l&#45;&#45;offset-1 ">-->
                <div :class="[message.onLeft?'two-col-split__image-left':'']"
                     class="chr-grid__col chr-grid__col--5 chr-grid__col--6 two-col-split__image-wrapper  chr-grid__col-l--offset-1 ">
                    <picture data-comp="LazyLoader" class="js-lazy-load">
                        <!--                                <source media="(max-width: 600px)"-->
                        <!--                                        srcset="img/save_password_global_mobile.png 1x, /static/img/save_password_global_mobile-2x.png 2x"-->
                        <!--                                        data-srcset="/chrome/static/images/benefits/module-1/save_password_global_mobile.png 1x, img/save_password_global_mobile-2x.png 2x">-->
                        <!--                                <source media="(min-width: 1024px)"-->
                        <!--                                        srcset="img/save_password_global_desktop.png 1x, img/save_password_global_desktop-2x.png 2x"-->
                        <!--                                        data-srcset="/chrome/static/images/benefits/module-1/save_password_global_desktop.png 1x, img/save_password_global_desktop-2x.png 2x">-->
                        <!--                                <source media="(min-width: 600px)"-->
                        <!--                                        srcset="img/save_password_global_tablet.png 1x, img/save_password_global_tablet-2x.png 2x"-->
                        <!--                                        data-srcset="../static/img/save_password_global_tablet.png 1x, ../static/img/save_password_global_tablet-2x.png 2x">-->
<!--                        <img data-src="../static/img/save_password_global_desktop.png"-->
<!--                             alt="专注的猫 云同步功能" src="../static/img/save_password_global_desktop.png">-->
<!--                        <img data-src="../static/img/save_password_global_desktop.png"-->
<!--                             alt="{{ message.title }}" src="../static/img/save_password_global_desktop.png">-->
                        <img :data-src="message.img" :alt="message.title" :src="message.img">
                    </picture>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    export default {
        name: "widget",
        // data: function () {
        //     return {
        //         // foo: 'bar'
        //         title: "heh"
        //     }
        // },
        props: {
            // message: String,
            message: {
                title: String,
                say: String,
                onLeft: true
            }
        }
    }
</script>

<style >

</style>