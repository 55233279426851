import { render, staticRenderFns } from "./Privacy.vue?vue&type=template&id=dd1d428a&scoped=true&"
import script from "./Privacy.vue?vue&type=script&lang=js&"
export * from "./Privacy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd1d428a",
  null
  
)

export default component.exports