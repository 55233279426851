<template>
    <div>
        <Useragreement></Useragreement>
    </div>
</template>

<script>
    import Useragreement from './components/user_protocol_com'

    export default {
        name: "useragreement",
        components:{
            Useragreement
        }
    }
</script>

<style scoped>

</style>