<template>
    <div>
        <h2>“专注的猫”用户使用许可协议</h2>
        <h3>一、总则</h3>
        <p>1.1 用户应当同意本协议的条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击"同意"按钮即表示用户与开发者达成协议，完全接受本协议项下的全部条款。<br>
            1.2 用户注册成功后，用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。<br>
            1.3 用户可以使用专注的猫各个功能服务，当用户使用专注的猫各功能服务时，用户的使用行为视为其对该功能服务的服务条款以及专注的猫在该功能服务中发出的各类公告的同意。<br>
            1.4 ”专注的猫”服务协议以及各个功能服务条款和公告可由开发者随时更新，且无需另行通知。您在使用相关服务时,应关注并遵守其所适用的相关条款。<br>
            您在使用提供的各项服务之前，应仔细阅读本服务协议。如您不同意本服务协议及/或随时对其的修改，您可以主动取消”专注的猫”提供的服务；
            您一旦使用”专注的猫”服务，即视为您已了解并完全同意本服务协议各项内容，包括”专注的猫”对服务协议随时所做的任何修改，并成为”专注的猫”用户。</p>
        <h3>二、注册信息和隐私保护</h3>
        <p>2.1 ”专注的猫”帐号（即”专注的猫”用户ID）的所有权归开发者，用户完成注册申请手续后，获得”专注的猫”帐号的使用权。用户应提供及时、详尽及准确的个人资料。
            所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，开发者不负任何责任。<br>
            2.2 用户不应将其帐号、密码转让、出售或出借予他人使用，如果开发者发现使用者并非帐号注册者本人，”专注的猫”有权停止继续服务。如用户发现其帐号遭他人非法使用，应立即通知”专注的猫”。
            因黑客行为或用户违反本协议规定导致帐号、密码遭他人非法使用的，由用户本人承担因此导致的损失和一切法律责任，开发者不承担任何责任。<br>
            2.3 使用”专注的猫”提供的服务，即表示您同意”专注的猫”合法收集和使用有关您及您所使用服务的技术性或诊断性信息。收集到的这些信息将用于改进”专注的猫”产品的内容和技术，提升”专注的猫”的服务品质。</p>
        <h3>三、使用规则</h3>
        <p>3.1 用户在使用”专注的猫”服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶<br>
            （1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：<br>
            1） 反对宪法所确定的基本原则的；<br>
            2） 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>
            3） 损害国家荣誉和利益的；<br>
            4） 煽动民族仇恨、民族歧视、破坏民族团结的；<br>
            5） 破坏国家宗教政策，宣扬邪教和封建迷信的；<br>
            6） 散布谣言，扰乱社会秩序，破坏社会稳定的；<br>
            7） 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>
            8） 侮辱或者诽谤他人，侵害他人合法权利的；<br>
            9） 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；<br>
            10） 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；<br>
            （2）不得为任何非法目的而使用网络服务系统；<br>
            （3）不利”专注的猫”服务从事以下活动：<br>
            1） 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；<br>
            2） 未经允许，对计算机信息网络功能进行删除、修改或者增加的；<br>
            3） 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；<br>
            4） 故意制作、传播计算机病毒等破坏性程序的；<br>
            5） 其他危害计算机信息网络安全的行为。<br>
            3.2 用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿开发者与合作公司、关联公司，并使之免受损害。
            对此，开发者有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收”专注的猫”帐号、追究法律责任等措施。
            对恶意注册”专注的猫”帐号或利用”专注的猫”帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，”专注的猫”有权回收其帐号。同时，开发者会视司法部门的要求，协助调查。<br>
            3.3 用户须对自己在使用”专注的猫”服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：
            对受到侵害者进行赔偿，以及在开发者首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予开发者等额的赔偿。</p>
        <h3>四、服务内容</h3>
        <p>4.1 ”专注的猫”网络服务的具体内容由开发者根据实际情况提供。<br>
            4.2 除非本服务协议另有其它明示规定，”专注的猫”所推出的新产品、新功能、新服务，均受到本服务协议之规范。<br>
            4.3 为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务费用。
            此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其它存取装置。<br>
            4.4 ”专注的猫”将尽合理的努力保护您的信息安全，并为此采取合理的数据传输、存储、转换等预防保护措施。
            但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。
            您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，并已采取恰当的措施（例如数据备份等），以便在该等风险发生时将损失降至最低。<br>
            因互联网技术本身等非”专注的猫”主观故意或重大过失导致危害您数据安全的，”专注的猫”不承担任何赔偿责任。因”专注的猫”重大过失危害您数据安全的，”专注的猫”的赔偿责任以向您收取的服务费用为上限。<br>
            4.5 鉴于网络服务的特殊性，用户同意”专注的猫”有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。
            ”专注的猫”不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。<br>
            4.6 6个月未登陆的帐号，”专注的猫”保留关闭的权利。<br>
            4.7 版权<br>
            ”专注的猫”的外观设计、计算机代码与专利等均归开发者所有。
            未经开发者事先书面许可，您不能复制、拷贝、或者使用任何部分的代码和外观设计。<br>
            4.8 免责声明<br>
            我们鼓励用户充分利用”专注的猫”自由地发布和共享自己的信息。您可以自由发布文字、图片等内容，但用户在”专注的猫”发布侵犯他人知识产权或其他合法权益的内容，”专注的猫”有权予以删除，并保留移交司法机关处理的权利；<br>
            用户对于自己创作并在”专注的猫”上发布的合法内容依法享有著作权及其他相关权利；<br>
            互联网是一个开放平台，用户将文字等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识到此类风险的存在。
            用户明确同意使用”专注的猫”服务所存在的风险将完全由用户自己承担；因用户使用”专注的猫”服务而产生的一切后果也由用户自己承担，”专注的猫”对用户不承担任何责任。<br>
            4.9 终止服务<br>
            如因系统维护或升级的需要而暂停网络服务、调整服务功能的，”专注的猫”将尽可能事先在网站上进行通告。<br>
            如发生下列任何一种情形，”专注的猫”有权单方面中断或终止向用户提供服务而无需通知用户：<br>
            用户提供的个人资料不真实；<br>
            用户违反本服务条款中规定的使用规则；<br>
            未经开发者同意，将”专注的猫”用于商业目的。</p>
        <h3>五、知识产权和其他合法权益（包括但不限于名誉权、商誉权）</h3>
        <p>“专注的猫”产品、技术与所有程序均属于开发者知识产权，在此并未授权。
            未经开发者许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用，否则，开发者将依法追究法律责任。<br>
            任何被授权的浏览、复制、打印和传播属于“专注的猫”产品内的资料必须符合以下条件：<br>
            所有的资料和图象均以获得信息为目的；<br>
            所有的资料和图象均不得用于商业目的；<br>
            所有的资料、图象及其任何部分都必须包括此版权声明.</p>
        <h3>六、免责声明</h3>
        <p>开发者提醒您：在您使用专注的猫及相关产品前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用专注的猫，但如果您使用专注的猫，您的使用行为将被视为对本声明全部内容的认可。</p>
        <h3>七、青少年用户特别提示</h3>
        <p>青少年用户必须遵守全国青少年网络文明公约：<br>
            要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。</p>
        <h3>八、其他</h3>
        <p>8.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
        <p> 8.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向开发者所在地的人民法院提起诉讼。</p>
        <p> 8.3 开发者未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</p>
        <p>8.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
    </div>
</template>

<script>
    export default {
        name: "useragreement"
    }
</script>

<style scoped>

</style>