<template>
    <div id="thank-you" class="no-mobile">
        <Head></Head>
        <Thank></Thank>
        <Down></Down>
        <Foot></Foot>
    </div>

</template>

<script>
    import Head from './components/Head'
    import Foot from './components/Foot'
    import Down from './components/down'
    import Thank from  './components/thank'

    /* import './js/installer' */
    import './js/main.v2.min'

    export default {
        name: "Thank-you",
        components: {
            Head,
            Foot,
            Down,
            Thank
        }
    }
</script>

<style>
    @import "./css/style.css";
    @import "./css/test.css";
    @import "./css/main.v2.min.css";
</style>