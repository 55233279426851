<template>
    <html>
    <head>
        <title>正在跳转</title>
        <meta http-equiv="Content-Language" content="zh-CN">
        <meta HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=gb2312">
        <!--        <meta http-equiv="refresh" content="0.2;url={{ url }}">-->
    </head>

    <body>

    </body>

    </html>
</template>

<script>

    function osType() {
        let userAgent = navigator.userAgent;
        if (userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) { // iphone or ipad
            return "ios";
            // return;
        } else if (userAgent.indexOf("Windows NT 5.1") > -1 || userAgent.indexOf("Windows XP") > -1) { // xp
            return "xp";
        } else if (userAgent.indexOf("Macintosh") > 0) {
            return "mac";
        } else if (userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1) { // 安卓
            return "android";
        } else if (userAgent.match("/MicroMessenger/i")) { // 微信
            return "wechat";
        } else if (userAgent.indexOf("Windows")) {
            return "windows";
        } else {
            // alert(userAgent);
            alert("现提供Windows版本、Mac版本、IOS版本和Android版本，其他版本近期上线，敬请期待!");
            return ""
        }
    }
    //
    function jump() {
        if (osType() === "ios") {
            window.location.href = "https://apps.apple.com/cn/app/%E4%B8%93%E6%B3%A8%E7%9A%84%E7%8C%AB/id1483734645?l=zh&ls=index";
        } else if (osType() === "android") {
            window.location.href = '/'
        } else {
            window.location.href = '/'
            // window.location.href = "https://apps.apple.com/cn/app/%E4%B8%93%E6%B3%A8%E7%9A%84%E7%8C%AB/id1483734645?l=zh&ls=index";
        }
        // return "window.location.href='/'";
        // return "/";
    }

    export default {
        osType,
        name: "jump",
        metaInfo: {
            title: "专注的猫 - 沉迷于手机会爆炸",
        },
        // mounted() {
        //     setTimeout(() => {
        //         // this.init()
        //         jump();
        //     }, 1000)
        //     // jump();
        // },
        //
        // watch: {
        //     analysisRank: function () {
        //         var _this = this;
        //         _this.$nextTick(function () {
        //             // computProcess()
        //             jump();
        //         });
        //     }
        //
        // },
        beforeCreate(){
            // jump();// 也不支持静态化
            setTimeout(() => {
                // this.init()
                jump();
            }, 100)
        }

    }


</script>

<style scoped>

</style>