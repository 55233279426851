import Vue from 'vue'
// import App from './Home.vue'
import VueMeta from 'vue-meta'
// import VueRouter from 'vue-router'

import Home from "./Home";
import ThankYou from "./Thank-you"
import Privacy from "./Privacy"
import privacy_clause from "./privacy_clause"
import user_protocol from "./user_protocol"
import jump from "./jump";

import './registerServiceWorker'

Vue.config.productionTip = false
Vue.use(VueMeta)

// Vue.use(VueRouter)

const routes = {
    '/': Home,
    '/thankyou.html': ThankYou,
    '/privacy.html': Privacy,
    '/privacy_clause.html': privacy_clause,
    '/user_protocol.html': user_protocol,
    '/jump.html': jump,
}

new Vue({
    el: '#app',
    data: {
        currentRoute: window.location.pathname
    },
    computed: {
        ViewComponent() {
            // return routes[this.currentRoute] || NotFound
            return routes[this.currentRoute] || Home
        }
    },
    render(h) {
        return h(this.ViewComponent)
    },
    mounted() {
        document.dispatchEvent(new Event('render-event'));
        // new ScrollMagic.Scene
    }

}).$mount('#app')





