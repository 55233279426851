import ScrollMagic from 'scrollmagic'

(function () {
    var aa = "function" == typeof Object.create ? Object.create : function (a) {
        function b() {
        }

        b.prototype = a;
        return new b
    }, h;
    if ("function" == typeof Object.setPrototypeOf) h = Object.setPrototypeOf; else {
        var l;
        a:{
            var ba = {J: !0}, ca = {};
            try {
                ca.__proto__ = ba;
                l = ca.J;
                break a
            } catch (a) {
            }
            l = !1
        }
        h = l ? function (a, b) {
            a.__proto__ = b;
            if (a.__proto__ !== b) throw new TypeError(a + " is not extensible");
            return a
        } : null
    }
    var da = h;

    function m(a, b) {
        a.prototype = aa(b.prototype);
        a.prototype.constructor = a;
        if (da) da(a, b); else for (var c in b) if ("prototype" != c) if (Object.defineProperties) {
            var d = Object.getOwnPropertyDescriptor(b, c);
            d && Object.defineProperty(a, c, d)
        } else a[c] = b[c];
        a.F = b.prototype
    }

    var ea = "function" == typeof Object.defineProperties ? Object.defineProperty : function (a, b, c) {
            a != Array.prototype && a != Object.prototype && (a[b] = c.value)
        },
        n = "undefined" != typeof window && window === this ? this : "undefined" != typeof global && null != global ? global : this;

    function fa() {
        fa = function () {
        };
        n.Symbol || (n.Symbol = ha)
    }

    var ha = function () {
        var a = 0;
        return function (b) {
            return "jscomp_symbol_" + (b || "") + a++
        }
    }();

    function p() {
        fa();
        var a = n.Symbol.iterator;
        a || (a = n.Symbol.iterator = n.Symbol("iterator"));
        "function" != typeof Array.prototype[a] && ea(Array.prototype, a, {
            configurable: !0,
            writable: !0,
            value: function () {
                return ia(this)
            }
        });
        p = function () {
        }
    }

    function ia(a) {
        var b = 0;
        return ja(function () {
            return b < a.length ? {done: !1, value: a[b++]} : {done: !0}
        })
    }

    function ja(a) {
        p();
        a = {next: a};
        a[n.Symbol.iterator] = function () {
            return this
        };
        return a
    }

    function ka(a) {
        p();
        var b = a[Symbol.iterator];
        return b ? b.call(a) : ia(a)
    }

    function q(a) {
        if (!(a instanceof Array)) {
            a = ka(a);
            for (var b, c = []; !(b = a.next()).done;) c.push(b.value);
            a = c
        }
        return a
    }

    for (var r = n, t = ["Promise"], la = 0; la < t.length - 1; la++) {
        var ma = t[la];
        ma in r || (r[ma] = {});
        r = r[ma]
    }
    var na = t[t.length - 1], oa = r[na], pa = function () {
        function a(a) {
            this.b = 0;
            this.g = void 0;
            this.a = [];
            var b = this.c();
            try {
                a(b.resolve, b.reject)
            } catch (k) {
                b.reject(k)
            }
        }

        function b() {
            this.a = null
        }

        function c(b) {
            return b instanceof a ? b : new a(function (a) {
                a(b)
            })
        }

        if (oa) return oa;
        b.prototype.b = function (a) {
            null == this.a && (this.a = [], this.f());
            this.a.push(a)
        };
        b.prototype.f = function () {
            var a = this;
            this.c(function () {
                a.h()
            })
        };
        var d = n.setTimeout;
        b.prototype.c = function (a) {
            d(a, 0)
        };
        b.prototype.h = function () {
            for (; this.a && this.a.length;) {
                var a =
                    this.a;
                this.a = [];
                for (var b = 0; b < a.length; ++b) {
                    var c = a[b];
                    delete a[b];
                    try {
                        c()
                    } catch (A) {
                        this.g(A)
                    }
                }
            }
            this.a = null
        };
        b.prototype.g = function (a) {
            this.c(function () {
                throw a;
            })
        };
        a.prototype.c = function () {
            function a(a) {
                return function (d) {
                    c || (c = !0, a.call(b, d))
                }
            }

            var b = this, c = !1;
            return {resolve: a(this.m), reject: a(this.f)}
        };
        a.prototype.m = function (b) {
            if (b === this) this.f(new TypeError("A Promise cannot resolve to itself")); else if (b instanceof a) this.s(b); else {
                a:switch (typeof b) {
                    case "object":
                        var c = null != b;
                        break a;
                    case "function":
                        c =
                            !0;
                        break a;
                    default:
                        c = !1
                }
                c ? this.w(b) : this.h(b)
            }
        };
        a.prototype.w = function (a) {
            var b = void 0;
            try {
                b = a.then
            } catch (k) {
                this.f(k);
                return
            }
            "function" == typeof b ? this.u(b, a) : this.h(a)
        };
        a.prototype.f = function (a) {
            this.i(2, a)
        };
        a.prototype.h = function (a) {
            this.i(1, a)
        };
        a.prototype.i = function (a, b) {
            if (0 != this.b) throw Error("Cannot settle(" + a + ", " + b | "): Promise already settled in state" + this.b);
            this.b = a;
            this.g = b;
            this.l()
        };
        a.prototype.l = function () {
            if (null != this.a) {
                for (var a = this.a, b = 0; b < a.length; ++b) a[b].call(), a[b] = null;
                this.a = null
            }
        };
        var e = new b;
        a.prototype.s = function (a) {
            var b = this.c();
            a.v(b.resolve, b.reject)
        };
        a.prototype.u = function (a, b) {
            var c = this.c();
            try {
                a.call(b, c.resolve, c.reject)
            } catch (A) {
                c.reject(A)
            }
        };
        a.prototype.then = function (b, c) {
            function d(a, b) {
                return "function" == typeof a ? function (b) {
                    try {
                        e(a(b))
                    } catch (rb) {
                        f(rb)
                    }
                } : b
            }

            var e, f, g = new a(function (a, b) {
                e = a;
                f = b
            });
            this.v(d(b, e), d(c, f));
            return g
        };
        a.prototype.catch = function (a) {
            return this.then(void 0, a)
        };
        a.prototype.v = function (a, b) {
            function c() {
                switch (d.b) {
                    case 1:
                        a(d.g);
                        break;
                    case 2:
                        b(d.g);
                        break;
                    default:
                        throw Error("Unexpected state: " + d.b);
                }
            }

            var d = this;
            null == this.a ? e.b(c) : this.a.push(function () {
                e.b(c)
            })
        };
        a.resolve = c;
        a.reject = function (b) {
            return new a(function (a, c) {
                c(b)
            })
        };
        a.race = function (b) {
            return new a(function (a, d) {
                for (var e = ka(b), f = e.next(); !f.done; f = e.next()) c(f.value).v(a, d)
            })
        };
        a.all = function (b) {
            var d = ka(b), e = d.next();
            return e.done ? c([]) : new a(function (a, b) {
                function f(b) {
                    return function (c) {
                        g[b] = c;
                        k--;
                        0 == k && a(g)
                    }
                }

                var g = [], k = 0;
                do g.push(void 0), k++, c(e.value).v(f(g.length -
                    1), b), e = d.next(); while (!e.done)
            })
        };
        return a
    }();
    pa != oa && null != pa && ea(r, na, {configurable: !0, writable: !0, value: pa});
    var u = this;

    function v(a) {
        return "string" == typeof a
    }

    function qa() {
    }

    function w(a) {
        var b = typeof a;
        if ("object" == b) if (a) {
            if (a instanceof Array) return "array";
            if (a instanceof Object) return b;
            var c = Object.prototype.toString.call(a);
            if ("[object Window]" == c) return "object";
            if ("[object Array]" == c || "number" == typeof a.length && "undefined" != typeof a.splice && "undefined" != typeof a.propertyIsEnumerable && !a.propertyIsEnumerable("splice")) return "array";
            if ("[object Function]" == c || "undefined" != typeof a.call && "undefined" != typeof a.propertyIsEnumerable && !a.propertyIsEnumerable("call")) return "function"
        } else return "null";
        else if ("function" == b && "undefined" == typeof a.call) return "object";
        return b
    }

    function x(a) {
        var b = typeof a;
        return "object" == b && null != a || "function" == b
    };var ra = String.prototype.trim ? function (a) {
        return a.trim()
    } : function (a) {
        return a.replace(/^[\s\xa0]+|[\s\xa0]+$/g, "")
    };

    function y(a, b) {
        var c = 0;
        a = ra(String(a)).split(".");
        b = ra(String(b)).split(".");
        for (var d = Math.max(a.length, b.length), e = 0; 0 == c && e < d; e++) {
            var f = a[e] || "", g = b[e] || "";
            do {
                f = /(\d*)(\D*)(.*)/.exec(f) || ["", "", "", ""];
                g = /(\d*)(\D*)(.*)/.exec(g) || ["", "", "", ""];
                if (0 == f[0].length && 0 == g[0].length) break;
                c = sa(0 == f[1].length ? 0 : parseInt(f[1], 10), 0 == g[1].length ? 0 : parseInt(g[1], 10)) || sa(0 == f[2].length, 0 == g[2].length) || sa(f[2], g[2]);
                f = f[3];
                g = g[3]
            } while (0 == c)
        }
        return c
    }

    function sa(a, b) {
        return a < b ? -1 : a > b ? 1 : 0
    }

    function ta() {
        return "glueCtrx".replace(/([A-Z])/g, "-$1").toLowerCase()
    };var ua = Array.prototype.indexOf ? function (a, b, c) {
        return Array.prototype.indexOf.call(a, b, c)
    } : function (a, b, c) {
        c = null == c ? 0 : 0 > c ? Math.max(0, a.length + c) : c;
        if (v(a)) return v(b) && 1 == b.length ? a.indexOf(b, c) : -1;
        for (; c < a.length; c++) if (c in a && a[c] === b) return c;
        return -1
    }, va = Array.prototype.forEach ? function (a, b, c) {
        Array.prototype.forEach.call(a, b, c)
    } : function (a, b, c) {
        for (var d = a.length, e = v(a) ? a.split("") : a, f = 0; f < d; f++) f in e && b.call(c, e[f], f, a)
    }, wa = Array.prototype.filter ? function (a, b, c) {
        return Array.prototype.filter.call(a,
            b, c)
    } : function (a, b, c) {
        for (var d = a.length, e = [], f = 0, g = v(a) ? a.split("") : a, k = 0; k < d; k++) if (k in g) {
            var A = g[k];
            b.call(c, A, k, a) && (e[f++] = A)
        }
        return e
    };
    var z;
    a:{
        var xa = u.navigator;
        if (xa) {
            var ya = xa.userAgent;
            if (ya) {
                z = ya;
                break a
            }
        }
        z = ""
    }

    function B(a) {
        return -1 != z.indexOf(a)
    };

    function za() {
        return B("Safari") && !(Aa() || B("Coast") || B("Opera") || B("Edge") || B("Silk") || B("Android"))
    }

    function Aa() {
        return (B("Chrome") || B("CriOS")) && !B("Edge")
    };

    function Ba() {
        return B("iPhone") && !B("iPod") && !B("iPad")
    }

    function C() {
        return Ba() || B("iPad") || B("iPod")
    };

    function Ca(a) {
        Ca[" "](a);
        return a
    }

    Ca[" "] = qa;
    var Da = B("Opera"), D = B("Trident") || B("MSIE"), Ea = B("Edge"),
        Fa = B("Gecko") && !(-1 != z.toLowerCase().indexOf("webkit") && !B("Edge")) && !(B("Trident") || B("MSIE")) && !B("Edge"),
        Ga = -1 != z.toLowerCase().indexOf("webkit") && !B("Edge"), Ha = B("Android"), Ia = C();

    function Ja() {
        var a = u.document;
        return a ? a.documentMode : void 0
    }

    var E;
    a:{
        var Ka = "", La = function () {
            var a = z;
            if (Fa) return /rv:([^\);]+)(\)|;)/.exec(a);
            if (Ea) return /Edge\/([\d\.]+)/.exec(a);
            if (D) return /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);
            if (Ga) return /WebKit\/(\S+)/.exec(a);
            if (Da) return /(?:Version)[ \/]?(\S+)/.exec(a)
        }();
        La && (Ka = La ? La[1] : "");
        if (D) {
            var Ma = Ja();
            if (null != Ma && Ma > parseFloat(Ka)) {
                E = String(Ma);
                break a
            }
        }
        E = Ka
    }
    var Na = {}, Oa;
    var Pa = u.document;
    Oa = Pa && D ? Ja() || ("CSS1Compat" == Pa.compatMode ? parseInt(E, 10) : 5) : void 0;

    function Qa(a) {
        var b = document;
        return v(a) ? b.getElementById(a) : a
    }

    function Ra(a, b) {
        var c = b || document;
        return c.querySelectorAll && c.querySelector ? c.querySelectorAll("." + a) : Sa(document, a, b)
    }

    function Ta(a, b) {
        var c = b || document;
        if (c.getElementsByClassName) a = c.getElementsByClassName(a)[0]; else {
            c = document;
            var d = b || c;
            a = d.querySelectorAll && d.querySelector && a ? d.querySelector("" + (a ? "." + a : "")) : Sa(c, a, b)[0] || null
        }
        return a || null
    }

    function Sa(a, b, c) {
        var d;
        a = c || a;
        if (a.querySelectorAll && a.querySelector && b) return a.querySelectorAll("" + (b ? "." + b : ""));
        if (b && a.getElementsByClassName) {
            var e = a.getElementsByClassName(b);
            return e
        }
        e = a.getElementsByTagName("*");
        if (b) {
            var f = {};
            for (c = d = 0; a = e[c]; c++) {
                var g = a.className, k;
                if (k = "function" == typeof g.split) k = 0 <= ua(g.split(/\s+/), b);
                k && (f[d++] = a)
            }
            f.length = d;
            return f
        }
        return e
    };

    function Ua(a) {
        if (a.classList) return a.classList;
        a = a.className;
        return v(a) && a.match(/\S+/g) || []
    }

    function F(a, b) {
        return a.classList ? a.classList.contains(b) : 0 <= ua(Ua(a), b)
    }

    function G(a, b) {
        a.classList ? a.classList.add(b) : F(a, b) || (a.className += 0 < a.className.length ? " " + b : b)
    }

    function H(a, b) {
        a.classList ? a.classList.remove(b) : F(a, b) && (a.className = wa(Ua(a), function (a) {
            return a != b
        }).join(" "))
    };

    function I(a, b) {
        this.a = a;
        this.w = b
    };

    function Va(a, b) {
        I.call(this, a, b);
        this.body = a || document.body;
        a = "no-mobile";
        Ia ? a = "ios" : Ha && (a = "android");
        G(this.body, a)
    }

    m(Va, I);

    function J(a, b) {
        I.call(this, a, b);
        this.m = new ScrollMagic.Controller;
        this.b = document.body;
        this.h = Qa("js-header");
        if (this.c = !F(this.h, "no-nav")) this.g = Qa("js-hamburger-button"), this.f = Qa("js-drawer-backdrop");
        this.c && (K(this.g, L, this.l, !1, this), K(this.f, L, this.i, !1, this));
        (new ScrollMagic.Scene({triggerElement: ".js-sticky-trigger"})).setClassToggle("body", "header--visible").addTo(this.m)
    }

    m(J, I);
    J.prototype.l = function () {
        var a = this;
        G(this.b, "is-showing-drawer");
        setTimeout(function () {
            G(a.b, "is-showing-backdrop")
        }, 100)
    };
    J.prototype.i = function () {
        var a = this;
        H(this.b, "is-showing-backdrop");
        setTimeout(function () {
            H(a.b, "is-showing-drawer")
        }, 100)
    };

    function Wa() {
        return window.getComputedStyle(document.body, ":after").getPropertyValue("content").replace(/["']/g, "")
    };var Xa = B("Firefox"), Ya = Ba() || B("iPod"), Za = B("iPad"),
        $a = B("Android") && !(Aa() || B("Firefox") || B("Opera") || B("Silk")), ab = Aa(), bb = za() && !C();

    function M(a) {
        return (a = a.exec(z)) ? a[1] : ""
    }

    var N = function () {
        if (Xa) return M(/Firefox\/([0-9.]+)/);
        if (D || Ea || Da) return E;
        if (ab) return C() ? M(/CriOS\/([0-9.]+)/) : M(/Chrome\/([0-9.]+)/);
        if (bb && !C()) return M(/Version\/([0-9.]+)/);
        if (Ya || Za) {
            var a = /Version\/(\S+).*Mobile\/(\S+)/.exec(z);
            if (a) return a[1] + "." + a[2]
        } else if ($a) return (a = M(/Android\s+([0-9.]+)/)) ? a : M(/Version\/([0-9.]+)/);
        return ""
    }();
    var cb = Object.freeze || function (a) {
        return a
    };
    var db;
    (db = !D) || (db = 9 <= Number(Oa));
    var eb = db, fb;
    if (fb = D) fb = !(Object.prototype.hasOwnProperty.call(Na, "9") ? Na["9"] : Na["9"] = 0 <= y(E, "9"));
    var gb = fb, hb = function () {
        if (!u.addEventListener || !Object.defineProperty) return !1;
        var a = !1, b = Object.defineProperty({}, "passive", {
            get: function () {
                a = !0
            }
        });
        u.addEventListener("test", qa, b);
        u.removeEventListener("test", qa, b);
        return a
    }();

    function O(a, b) {
        this.type = a;
        this.currentTarget = this.target = b
    }

    O.prototype.preventDefault = function () {
    };
    var L = "click";

    function P(a, b) {
        O.call(this, a ? a.type : "");
        this.relatedTarget = this.currentTarget = this.target = null;
        this.button = this.screenY = this.screenX = this.clientY = this.clientX = 0;
        this.key = "";
        this.keyCode = 0;
        this.metaKey = this.shiftKey = this.altKey = this.ctrlKey = !1;
        this.pointerId = 0;
        this.pointerType = "";
        this.a = null;
        if (a) {
            var c = this.type = a.type, d = a.changedTouches ? a.changedTouches[0] : null;
            this.target = a.target || a.srcElement;
            this.currentTarget = b;
            if (b = a.relatedTarget) {
                if (Fa) {
                    a:{
                        try {
                            Ca(b.nodeName);
                            var e = !0;
                            break a
                        } catch (f) {
                        }
                        e =
                            !1
                    }
                    e || (b = null)
                }
            } else "mouseover" == c ? b = a.fromElement : "mouseout" == c && (b = a.toElement);
            this.relatedTarget = b;
            null === d ? (this.clientX = void 0 !== a.clientX ? a.clientX : a.pageX, this.clientY = void 0 !== a.clientY ? a.clientY : a.pageY, this.screenX = a.screenX || 0, this.screenY = a.screenY || 0) : (this.clientX = void 0 !== d.clientX ? d.clientX : d.pageX, this.clientY = void 0 !== d.clientY ? d.clientY : d.pageY, this.screenX = d.screenX || 0, this.screenY = d.screenY || 0);
            this.button = a.button;
            this.keyCode = a.keyCode || 0;
            this.key = a.key || "";
            this.ctrlKey = a.ctrlKey;
            this.altKey = a.altKey;
            this.shiftKey = a.shiftKey;
            this.metaKey = a.metaKey;
            this.pointerId = a.pointerId || 0;
            this.pointerType = v(a.pointerType) ? a.pointerType : ib[a.pointerType] || "";
            this.a = a;
            a.defaultPrevented && this.preventDefault()
        }
    }

    (function () {
        function a() {
        }

        a.prototype = O.prototype;
        P.F = O.prototype;
        P.prototype = new a;
        P.prototype.constructor = P;
        P.a = function (a, c, d) {
            for (var b = Array(arguments.length - 2), f = 2; f < arguments.length; f++) b[f - 2] = arguments[f];
            return O.prototype[c].apply(a, b)
        }
    })();
    var ib = cb({2: "touch", 3: "pen", 4: "mouse"});
    P.prototype.preventDefault = function () {
        P.F.preventDefault.call(this);
        var a = this.a;
        if (a.preventDefault) a.preventDefault(); else if (a.returnValue = !1, gb) try {
            if (a.ctrlKey || 112 <= a.keyCode && 123 >= a.keyCode) a.keyCode = -1
        } catch (b) {
        }
    };
    var Q = "closure_listenable_" + (1E6 * Math.random() | 0), jb = 0;

    function kb(a, b, c, d, e) {
        this.listener = a;
        this.a = null;
        this.src = b;
        this.type = c;
        this.capture = !!d;
        this.b = e;
        this.key = ++jb;
        this.o = this.A = !1
    }

    function lb(a) {
        a.o = !0;
        a.listener = null;
        a.a = null;
        a.src = null;
        a.b = null
    };

    function R(a) {
        this.src = a;
        this.a = {};
        this.b = 0
    }

    R.prototype.add = function (a, b, c, d, e) {
        var f = a.toString();
        a = this.a[f];
        a || (a = this.a[f] = [], this.b++);
        var g = mb(a, b, d, e);
        -1 < g ? (b = a[g], c || (b.A = !1)) : (b = new kb(b, this.src, f, !!d, e), b.A = c, a.push(b));
        return b
    };
    R.prototype.remove = function (a, b, c, d) {
        a = a.toString();
        if (!(a in this.a)) return !1;
        var e = this.a[a];
        b = mb(e, b, c, d);
        return -1 < b ? (lb(e[b]), Array.prototype.splice.call(e, b, 1), 0 == e.length && (delete this.a[a], this.b--), !0) : !1
    };

    function mb(a, b, c, d) {
        for (var e = 0; e < a.length; ++e) {
            var f = a[e];
            if (!f.o && f.listener == b && f.capture == !!c && f.b == d) return e
        }
        return -1
    };var nb = "closure_lm_" + (1E6 * Math.random() | 0), ob = {}, pb = 0;

    function K(a, b, c, d, e) {
        if (d && d.once) qb(a, b, c, d, e); else if ("array" == w(b)) for (var f = 0; f < b.length; f++) K(a, b[f], c, d, e); else c = sb(c), a && a[Q] ? a.a(b, c, x(d) ? !!d.capture : !!d, e) : tb(a, b, c, !1, d, e)
    }

    function tb(a, b, c, d, e, f) {
        if (!b) throw Error("Invalid event type");
        var g = x(e) ? !!e.capture : !!e, k = S(a);
        k || (a[nb] = k = new R(a));
        c = k.add(b, c, d, g, f);
        if (!c.a) {
            d = ub();
            c.a = d;
            d.src = a;
            d.listener = c;
            if (a.addEventListener) hb || (e = g), void 0 === e && (e = !1), a.addEventListener(b.toString(), d, e); else if (a.attachEvent) a.attachEvent(vb(b.toString()), d); else throw Error("addEventListener and attachEvent are unavailable.");
            pb++
        }
    }

    function ub() {
        var a = wb, b = eb ? function (c) {
            return a.call(b.src, b.listener, c)
        } : function (c) {
            c = a.call(b.src, b.listener, c);
            if (!c) return c
        };
        return b
    }

    function qb(a, b, c, d, e) {
        if ("array" == w(b)) for (var f = 0; f < b.length; f++) qb(a, b[f], c, d, e); else c = sb(c), a && a[Q] ? a.b(b, c, x(d) ? !!d.capture : !!d, e) : tb(a, b, c, !0, d, e)
    }

    function xb(a, b, c, d, e) {
        if ("array" == w(b)) for (var f = 0; f < b.length; f++) xb(a, b[f], c, d, e); else (d = x(d) ? !!d.capture : !!d, c = sb(c), a && a[Q]) ? a.c(b, c, d, e) : a && (a = S(a)) && (b = a.a[b.toString()], a = -1, b && (a = mb(b, c, d, e)), (c = -1 < a ? b[a] : null) && yb(c))
    }

    function yb(a) {
        if ("number" != typeof a && a && !a.o) {
            var b = a.src;
            if (b && b[Q]) b.f(a); else {
                var c = a.type, d = a.a;
                b.removeEventListener ? b.removeEventListener(c, d, a.capture) : b.detachEvent && b.detachEvent(vb(c), d);
                pb--;
                if (c = S(b)) {
                    d = a.type;
                    var e;
                    if (e = d in c.a) {
                        e = c.a[d];
                        var f = ua(e, a), g;
                        (g = 0 <= f) && Array.prototype.splice.call(e, f, 1);
                        e = g
                    }
                    e && (lb(a), 0 == c.a[d].length && (delete c.a[d], c.b--));
                    0 == c.b && (c.src = null, b[nb] = null)
                } else lb(a)
            }
        }
    }

    function vb(a) {
        return a in ob ? ob[a] : ob[a] = "on" + a
    }

    function zb(a, b, c, d) {
        var e = !0;
        if (a = S(a)) if (b = a.a[b.toString()]) for (b = b.concat(), a = 0; a < b.length; a++) {
            var f = b[a];
            f && f.capture == c && !f.o && (f = Ab(f, d), e = e && !1 !== f)
        }
        return e
    }

    function Ab(a, b) {
        var c = a.listener, d = a.b || a.src;
        a.A && yb(a);
        return c.call(d, b)
    }

    function wb(a, b) {
        if (a.o) return !0;
        if (!eb) {
            if (!b) a:{
                b = ["window", "event"];
                for (var c = u, d = 0; d < b.length; d++) if (c = c[b[d]], null == c) {
                    b = null;
                    break a
                }
                b = c
            }
            d = b;
            b = new P(d, this);
            c = !0;
            if (!(0 > d.keyCode || void 0 != d.returnValue)) {
                a:{
                    var e = !1;
                    if (0 == d.keyCode) try {
                        d.keyCode = -1;
                        break a
                    } catch (g) {
                        e = !0
                    }
                    if (e || void 0 == d.returnValue) d.returnValue = !0
                }
                d = [];
                for (e = b.currentTarget; e; e = e.parentNode) d.push(e);
                a = a.type;
                for (e = d.length - 1; 0 <= e; e--) {
                    b.currentTarget = d[e];
                    var f = zb(d[e], a, !0, b);
                    c = c && f
                }
                for (e = 0; e < d.length; e++) b.currentTarget =
                    d[e], f = zb(d[e], a, !1, b), c = c && f
            }
            return c
        }
        return Ab(a, new P(b, this))
    }

    function S(a) {
        a = a[nb];
        return a instanceof R ? a : null
    }

    var Bb = "__closure_events_fn_" + (1E9 * Math.random() >>> 0);

    function sb(a) {
        if ("function" == w(a)) return a;
        a[Bb] || (a[Bb] = function (b) {
            return a.handleEvent(b)
        });
        return a[Bb]
    };

    function T(a, b) {
        I.call(this, a, b);
        this.c = Ra("js-footer-link");
        Cb(this)
    }

    m(T, I);

    function Cb(a) {
        (!D || D && 0 <= y(N, 10)) && [].concat(q(a.c)).forEach(function (b) {
            K(b, "click", a.b, !1, a);
            K(b, "keydown", a.f, !1, a)
        })
    }

    T.prototype.b = function (a) {
        if ("x-small" === Wa() || "small" === Wa()) a.currentTarget.parentNode.classList.toggle("chr-footer-links__group--expanded"), a.preventDefault()
    };
    T.prototype.f = function (a) {
        0 !== a.keyCode && 32 !== a.keyCode && 13 !== a.keyCode || this.b(a)
    };

    function Db(a) {
        for (var b = a.offsetTop, c = a.offsetLeft, d = a.offsetWidth, e = a.offsetHeight; a.offsetParent;) a = a.offsetParent, b += a.offsetTop, c += a.offsetLeft;
        return b < window.pageYOffset + window.innerHeight && c < window.pageXOffset + window.innerWidth && b + e > window.pageYOffset && c + d > window.pageXOffset
    };!D || 0 <= y(N, 10) || document.attachEvent || (eb = !0);
    var Eb = {
        fallback: {C: "data-g-action", D: "data-g-event", I: "data-g-label"},
        latest: {C: "ga-event-action", D: "ga-event-category", I: "ga-event-label"}
    };

    function U() {
        this.b = !D || 0 <= y(N, 10) || !document.attachEvent ? "latest" : "fallback"
    }

    U.prototype.a = function (a, b, c, d, e, f) {
        var g = window.ga || window.tracker || window.gaTracker;
        if (g) if ("latest" === this.b) g("send", a, b, c, d, e, f); else {
            var k = Array.prototype.slice.call(arguments);
            k = k.filter(function (a) {
                if (null != a) return "string" != typeof a ? a.toString() : a
            });
            g.pushCommand(k)
        }
    };
    U.prototype.j = function (a, b, c, d, e) {
        this.a("event", a, b, c, d, e)
    };

    function V(a, b) {
        I.call(this, a, b);
        this.a = a;
        this.f = this.a.getAttribute("data-trackedsection");
        this.g = this.a.getAttribute("data-trackedoffset");
        this.c = !1;
        this.b = null;
        this.h = new U;
        Fb(this)
    }

    m(V, I);
    V.prototype.name = function () {
        return this.f
    };

    function Fb(a) {
        window.addEventListener("scroll", function () {
            Db(a.a) ? Gb(a) : a.b && !Db(a.a) && (clearTimeout(a.b), a.b = null)
        })
    }

    function Gb(a) {
        a.timeout || a.c || (a.b = setTimeout(function () {
            a.j()
        }, 2E3))
    }

    V.prototype.j = function () {
        var a = !1, b = Db(this.a);
        !this.c && b && (a = !0, this.g && (a = this.a.offsetParent.offsetTop && "BODY" !== this.a.tagName));
        a && (a = {}, this.h.j("scroll-depth", "scrolled", this.name(), void 0, (a.nonInteraction = !0, a)), this.c = !0)
    };

    function W(a, b) {
        I.call(this, a, b);
        (this.f = document.querySelectorAll("[data-youtube-id]")) && this.f.length && (this.s = this.a.querySelector(".js-video-modal__close-button"), this.body = document.body, this.l = document.querySelector(".chr-main"), this.g = this.f[0].getAttribute("data-youtube-id"), this.H = this.a.querySelector(".js-video-modal__dark-overlay"), this.B = document.getElementById("video-iframe"), this.K = document.querySelector(".js-video-modal__iframe-wrapper"), this.h = this.i = !1, this.b = new U, Hb(this))
    }

    m(W, I);

    function Hb(a) {
        va(a.f, function (b) {
            b.addEventListener("click", a.m.bind(a))
        });
        a.s.addEventListener("click", a.close.bind(a));
        a.Win49OS.addEventListener("click", a.close.bind(a));
        window.addEventListener("keydown", a.u.bind(a))
    }

    W.prototype.m = function (a) {
        Ib(this);
        this.c ? this.playVideo(a) : Jb(this).then(this.playVideo.bind(this, a))
    };

    function Ib(a) {
        a.h = !0;
        a.a.classList.add("chr-video-modal--open");
        a.body.classList.add("chr-video-modal-open");
        a.l.setAttribute("aria-hidden", !0);
        setTimeout(function () {
            a.a.classList.add("chr-video-modal--opaque")
        }, 200)
    }

    W.prototype.playVideo = function (a) {
        a = a.target.getAttribute("data-youtube-id");
        this.i && (a !== this.g ? (this.c.loadVideoById(a), this.g = a) : this.c.playVideo());
        this.b.j("video-modal", "open", "video: " + a)
    };
    W.prototype.close = function () {
        var a = this;
        this.h = !1;
        this.a.classList.remove("chr-video-modal--opaque");
        this.body.classList.remove("chr-video-modal-open");
        this.l.setAttribute("aria-hidden", !1);
        setTimeout(function () {
            a.a.classList.remove("chr-video-modal--open")
        }, 200);
        this.i && (this.c.pauseVideo(), this.b.j("video-modal", "close", "video: " + this.c.getVideoData().video_id))
    };

    function Kb(a, b, c, d) {
        return new YT.Player(b, {
            width: "100%",
            height: "100%",
            frameborder: 0,
            enablejsapi: 1,
            allowfullscreen: "allowfullscreen",
            playerVars: {rel: 0, M: 0, N: 0, L: 1},
            videoId: c,
            widget_referrer: window.location.href,
            events: {
                onReady: function () {
                    a.i = !0;
                    a.K.classList.add("js-video-modal__iframe-wrapper--loaded");
                    d()
                }, onStateChange: a.G.bind(a)
            }
        })
    }

    W.prototype.G = function (a) {
        var b = a.target.getVideoData().video_id, c = Math.round(a.target.getCurrentTime()),
            d = Math.round(a.target.getDuration());
        a.data == YT.PlayerState.ENDED ? this.b.j("video-modal", "end", "video: " + b) : a.data == YT.PlayerState.PLAYING ? this.b.j("video-modal", "play", "video: " + b + " | current_time: " + c + " | duration: " + d) : a.data == YT.PlayerState.PAUSED && this.b.j("video-modal", "pause", "video: " + b + " | current_time: " + c + " | duration: " + d)
    };

    function Jb(a) {
        return new Promise(function (b) {
            var c = document.createElement("script");
            c.src = "https://www.youtube.com/iframe_api";
            var d = document.getElementsByTagName("script")[0];
            d.parentNode.insertBefore(c, d);
            window.onYouTubeIframeAPIReady = function () {
                a.c = Kb(a, a.B, a.g, b)
            }
        })
    }

    W.prototype.u = function (a) {
        if (!this.h) return !1;
        if (27 === a.keyCode || "Escape" === a.key || "Esc" === a.key) return this.close(), !0
    };
    var Lb = !D && !za();

    function Mb(a, b) {
        I.call(this, a, b);
        this.f = new ScrollMagic.Controller;
        Nb(this)
    }

    m(Mb, I);

    function Nb(a) {
        a.b = bodymovin.loadAnimation({
            container: a.a,
            renderer: "svg",
            loop: "true" === a.w.loop,
            autoplay: !1,
            path: a.w.data
        });
        a.b.addEventListener("DOMLoaded", function () {
            (new ScrollMagic.Scene({triggerElement: a.a, O: .5})).on("enter", function () {
                a.b.goToAndPlay(0)
            }).addTo(a.f);
            a.b.removeEventListener("DOMLoaded")
        });
        a.a.addEventListener("click", a.c.bind(a))
    }

    Mb.prototype.c = function () {
        var a = this.a.parentElement;
        !/-[a-z]/.test("glueCtrx") && (Lb && a.dataset ? "glueCtrx" in a.dataset : a.hasAttribute ? a.hasAttribute("data-" + ta()) : a.getAttribute("data-" + ta())) && F(a, "ng-hide") || this.b.goToAndPlay(0)
    };

    function Ob(a) {
        function b() {
            d = u.setTimeout(c, 200);
            a.apply(void 0, f)
        }

        function c() {
            d = 0;
            e && (e = !1, b())
        }

        var d = 0, e = !1, f = [];
        return function (a) {
            f = arguments;
            d ? e = !0 : b()
        }
    };

    // function Pb(a, b) {
    //     I.call(this, a, b);
    //     a = window.devicePixelRatio;
    //     this.offset = !a || 1.5 > a ? 300 : 500;
    //     this.b = Ob(this.c.bind(this));
    //     window.addEventListener("resize", this.b, {pasive: !0});
    //     window.addEventListener("scroll", this.b, {pasive: !0});
    //     this.c()
    // }

    // m(Pb, I);
    // Pb.prototype.c = function () {
    //     0 > this.a.getBoundingClientRect().top - window.innerHeight - this.offset && Qb(this)
    // };

    function Qb(a) {
        var b = a.a.querySelector("[data-src]");
        [].concat(q(a.a.querySelectorAll("[data-srcset]"))).forEach(function (a) {
            a.setAttribute("srcset", a.getAttribute("data-srcset"))
        });
        b.setAttribute("src", b.getAttribute("data-src"));
        window.removeEventListener("resize", a.b);
        window.removeEventListener("scroll", a.b)
    };

    function Rb(a, b) {
        I.call(this, a, b);
        this.h = this.a.querySelectorAll(".js-tabs-icon");
        this.c = this.a.querySelector(".chr-tabs__icon--active");
        this.b = this.a.querySelector(".chr-tabs__panel--active");
        this.f = this.b.getAttribute("data-icon-id");
        Sb(this)
    }

    m(Rb, I);

    function Sb(a) {
        va(a.jsLinuxDistroOptions, function (b) {
            b.addEventListener("click", a.g.bind(a))
        })
    }

    Rb.prototype.g = function (a) {
        a.preventDefault();
        this.timeout && clearTimeout(this.timeout);
        a = a.currentTarget;
        var b = a.getAttribute("data-icon-id");
        this.f !== b && (this.c.classList.remove("chr-tabs__icon--active"), this.b.classList.remove("chr-tabs__panel--opaque"), this.b.classList.remove("chr-tabs__panel--active"), this.f = b, this.c = a, this.c.getAttribute("data-panel-label"), this.b = this.a.querySelector("[data-panel-id=" + b + "]"), Tb(this))
    };

    function Tb(a) {
        a.c.classList.add("chr-tabs__icon--active");
        a.b.classList.add("chr-tabs__panel--active");
        a.timeout = window.setTimeout(function () {
            a.b.classList.add("chr-tabs__panel--opaque")
        }, 150)
    }

    function X(a, b) {
        I.call(this, a, b);
        K(a, "click", this.b, !1, this);
        K(a, "keydown", this.c, !1, this)
    }

    m(X, I);
    X.prototype.c = function (a) {
        0 !== a.keyCode && 32 !== a.keyCode && 13 !== a.keyCode || this.b(a)
    };
    X.prototype.b = function (a) {
        function b() {
            g += 10;
            var a = g;
            a /= 125;
            1 > a ? a = f / 2 * a * a + e : (a--, a = -f / 2 * (a * (a - 2) - 1) + e);
            d.scrollTop = a;
            250 > g && setTimeout(b, 10)
        }

        var c = a.currentTarget.getAttribute("href"), d = document.scrollingElement || document.documentElement,
            e = d.scrollTop, f = document.querySelector(c).offsetTop - 64 - e, g = 0;
        b();
        a.preventDefault()
    };

    function Y(a, b) {
        I.call(this, a, b);
        this.i = Ra("js-carousel-panel", a);
        this.b = Ra("js-carousel-pagination", a);
        this.l = Ta("js-carousel-prev", a);
        this.next = Ta("js-carousel-next", a);
        this.f = a;
        this.index = 1;
        this.h = null;
        Ub(this)
    }

    m(Y, I);

    function Ub(a) {
        [].concat(q(a.b)).forEach(function (b) {
            K(b, L, a.m.bind(a, parseInt(b.getAttribute("data-panel-index"))), !1, a)
        });
        K(a.l, L, a.g, !1, a);
        K(a.next, L, a.c, !1, a);
        K(a.f, "touchstart", a.u, !1, a);
        K(a.f, "touchend", a.s, !1, a);
        Z(a)
    }

    Y.prototype.u = function (a) {
        this.B = a.clientX
    };
    Y.prototype.s = function (a) {
        a = this.B - a.clientX;
        80 < Math.abs(a) && (0 > a ? this.g() : this.c())
    };

    function Vb(a) {
        clearInterval(a.h);
        a.h = setInterval(function () {
            a.c()
        }, 1E4)
    }

    Y.prototype.m = function (a) {
        this.index = a;
        Z(this)
    };
    Y.prototype.c = function () {
        this.index >= this.b.length ? this.index = 1 : this.index++;
        Z(this)
    };
    Y.prototype.g = function () {
        1 >= this.index ? this.index = this.b.length : this.index--;
        Z(this)
    };

    function Z(a) {
        [].concat(q(a.b)).forEach(function (b) {
            H(b, "chr-carousel-pagination__button--active");
            parseInt(b.getAttribute("data-panel-index")) == a.index && G(b, "chr-carousel-pagination__button--active")
        });
        [].concat(q(a.i)).forEach(function (b) {
            H(b, "chr-simple-carousel__panel--active");
            parseInt(b.getAttribute("data-panel-index")) == a.index && G(b, "chr-simple-carousel__panel--active")
        });
        Vb(a)
    };var Wb = {
        EnvironmentDetect: Va,
        Header: J,
        Footer: T,
        TrackedSection: V,
        VideoModal: W,
        AnimatedSvg: Mb,
        // LazyLoader: Pb,
        Tabs: Rb,
        AnchorArrow: X,
        Carousel: Y
    }, Xb = null;

    function Yb() {
        Xb || (Xb = this, Zb());
        return Xb
    }

    function $b() {
        var a = document;
        a = void 0 === a ? document : a;
        return [].concat(q(a.querySelectorAll("[data-comp]")))
    }

    function Zb() {
        $b().forEach(function (a) {
            return ac(a)
        })
    }

    function ac(a) {
        var b = a.getAttribute("data-comp").split(" ");
        [].concat(q(b)).forEach(function (b) {
            var c = Wb[b], e = "data-props-" + b.toLowerCase(), f = {};
            try {
                f = JSON.parse(a.getAttribute(e))
            } catch (g) {
                bc(b, g.message)
            }
            "function" === typeof c ? new c(a, f) : bc(b, "Function not found in REGISTRY")
        })
    }

    function bc(a, b) {
        console.warn("Error bootstrapping data-comp " + a + ": " + b);
    }

    function cc() {
        this.a = Ta("js-footer-language-select");
        this.h = window;
        this.g = new U;
        this.f = !1;
        this.c = !D || 0 <= y(N, 10) || !document.attachEvent ? "latest" : "fallback";
        this.a && K(this.a, "change", this.i, !1, this);
        K(window, "scroll", this.b, !1, this)
    }

    cc.prototype.b = function () {
        if (this.f) xb(window, "scroll", this.b, !1, this); else if ((this.h.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) + (this.h.pageYOffset || document.documentElement.scrollTop) >= document.body.offsetHeight) {
            var a = {};
            this.g.j("scroll-depth", "scrolled", "bottom-of-page", void 0, (a.nonInteraction = !0, a));
            this.f = !0
        }
    };
    cc.prototype.i = function (a) {
        var b = a.target;
        a = b.getAttribute(Eb[this.c].D) || "";
        var c = "language:" + b.options[b.selectedIndex].text;
        b = b.getAttribute(Eb[this.c].C) || "clicked";
        var d = {};
        this.g.j(a, b, c, void 0, (d.nonInteraction = !0, d))
    };
    K(document, "DOMContentLoaded", function () {
        new Yb;
        new cc
    });
}).call(window);
